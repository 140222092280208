import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as AutomaticDiscountsTypes from './types';

//
//

export class ManagerAutomaticDiscounts extends ZidderResource<ManagerAutomaticDiscounts> {
  protected classInstance = ManagerAutomaticDiscounts;
  public pathBase = 'v1/managers/store/discounts/';

  //

  async list(query?: AutomaticDiscountsTypes.DiscountListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<AutomaticDiscountsTypes.DiscountRulesResponse>();

    if (!result.discount_rules) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    payload: AutomaticDiscountsTypes.PayloadUpdateDiscounts,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + payload.id, {
      ...options,
      json: payload,
    });
    const result = await response.json<AutomaticDiscountsTypes.DiscountRuleUpdateResponse>();

    if (!result.discount_rule) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
