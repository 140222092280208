class AppErrorGeneric extends Error {
  name = 'AppErrorGeneric';
}

export class AppErrorNotFound extends AppErrorGeneric {
  name = 'AppErrorNotFound';
  i18nMessage = 'error:notFound';
}

export class AppErrorIncorrectId extends AppErrorGeneric {
  name = 'AppErrorIncorrectId';
  i18nMessage = 'error:incorrectId';
}

export class AppErrorMissingPermission extends AppErrorGeneric {
  name = 'AppErrorMissingPermission';
  i18nMessage = 'error:missingPermission';
}

export class AppErrorUpgradeRequired extends AppErrorGeneric {
  name = 'AppErrorUpgradeRequired';
  i18nMessage = 'error:upgradeRequired';
}

export class AppErrorNoInternetConnection extends AppErrorGeneric {
  name = 'AppErrorNoInternetConnection';
  i18nMessage = 'error:noInternetConnection';
}
