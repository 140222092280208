import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as CitiesTypes from './cities.types';
import type * as CountriesTypes from './countries.types';

//
//

export class Settings extends ZidderResource<Settings> {
  protected classInstance = Settings;
  public pathBase = 'v1/settings/';

  //

  async listCountries(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'countries', {
      ...options,
    });
    const result = await response.json<CountriesTypes.SettingsCountriesResponse>();

    if (!result?.countries?.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async cities(query?: CitiesTypes.SettingsCitiesQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'cities', {
      searchParams: { ...query, ...options?.searchParams },
      ...options,
    });

    const result = await response.json<CitiesTypes.SettingsCountriesResponse>();

    if (!result?.cities?.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async citiesByCountryId(
    query: CitiesTypes.SettingsCityByCountryQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(`${this.pathBase}cities/by-country-id/${query.id}`, {
      ...options,
    });

    const result = await response.json<CitiesTypes.SettingsCityByCountryIdResponse>();

    if (!result?.cities?.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
