import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as CouponsReportTypes from './list.types';

//
//

export class ManagerCouponsReport extends ZidderResource<ManagerCouponsReport> {
  protected classInstance = ManagerCouponsReport;
  public pathBase = 'v1/managers/store/coupons/report';

  async list(query?: CouponsReportTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<CouponsReportTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
