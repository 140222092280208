import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { zidderGetHeaders } from '~/zidderjs/utils';

import * as CreateTypes from './create.types';
import * as PaymentMethodsTypes from './get.types';
import * as UpdateStatusTypes from './update-status.types';
import * as UpdateTypes from './update.types';

//
//

export class ManagerZidposPaymentMethods extends ZidderResource<ManagerZidposPaymentMethods> {
  protected classInstance = ManagerZidposPaymentMethods;
  public pathBase = 'v1/managers/pos/payment-methods';

  async list(query?: PaymentMethodsTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<PaymentMethodsTypes.Response>();

    if (!result?.pos_payment_methods) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(data: CreateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase, {
      json: data,
      ...options,
    });

    const result = await response.json<CreateTypes.Response>();

    if (!result?.pos_payment_method) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update({ id, names }: UpdateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + '/' + id, {
      json: { names },
      ...options,
    });

    const result = await response.json<UpdateTypes.Response>();

    if (!result?.pos_payment_method) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateStatus(data: UpdateStatusTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + '/' + data.id + '/status', {
      json: { is_active: data.is_active },
      ...options,
    });
    const result = await response.json<UpdateStatusTypes.Response>();

    if (!result?.pos_payment_method) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
