import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as ListTypes from './list.types';
import type * as UpdateTypes from './update.types';

//
//

export class ManagerCurrencies extends ZidderResource<ManagerCurrencies> {
  protected classInstance = ManagerCurrencies;

  public pathBase = 'v1/managers/store/currencies';

  //

  async storeCurrenciesList(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.currencies) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async currenciesListAvailable(query: ListTypes.Request, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}/all`, {
      searchParams: { ...query },
      ...options,
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.currencies) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateCurrencies(
    data: UpdateTypes.UpdateCurrenciesRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      json: { ...data },
    });

    const result = await response.json<UpdateTypes.UpdateCurrenciesResponse>();

    if (!result?.currencies) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDefaultCurrency(
    data: UpdateTypes.UpdateDefaultCurrencyRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}/default`, {
      ...options,
      json: { ...data },
    });

    const result = await response.json<UpdateTypes.UpdateDefaultCurrencyResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deactivateCurrency({ id }: UpdateTypes.DeactivateRequest, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}/${id}`, {
      ...options,
    });

    const result = await response.json<UpdateTypes.DeactivateResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
