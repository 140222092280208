import { createMutation, createQuery } from 'react-query-kit';

import { ZidderClient, ZidderRequestOptions } from '~/zidderjs';
import { Account } from '~/zidderjs/resources/manager/account/account.types';
import { ManagerProfileGetResponse } from '~/zidderjs/resources/manager/account/profile.types';
import { Response } from '~/zidderjs/resources/manager/auth/login.types';

import { queryClient } from './client';

//
//

const key = 'profile';

export const useQueryProfile = createQuery<ManagerProfileGetResponse, ZidderRequestOptions>({
  queryKey: [key],
  enabled: typeof document !== 'undefined' && !!window?.localStorage?.getItem('_t'),
  staleTime: 15 * 60 * 1000, // 15 minutes
  fetcher: (options?: ZidderRequestOptions) => ZidderClient.managerAccount.profile(options),
});

//
export const useMutationProfileUpdate = createMutation<ManagerProfileGetResponse, Account>({
  mutationKey: [key, 'update'],
  mutationFn: payload => ZidderClient.managerAccount.updateAccount(payload),
});

export interface ChangePasswordPayload {
  old_password: string | undefined;
  password: string;
  password_confirmation: string;
}

export const useMutationProfileUpdatePassword = createMutation<
  { message: string },
  ChangePasswordPayload
>({
  mutationKey: [key, 'updatePassword'],
  mutationFn: async payload => ZidderClient.managerAccount.updatePassword(payload),
});

//
//

export const setProfileCache = async (data: Response) => {
  const { access_token, ...user } = data?.user || {}; // eslint-disable-line @typescript-eslint/no-unused-vars

  await queryClient.setQueryData([key], user);
};

export const refetchProfileNoCache = async () =>
  queryClient.fetchQuery({
    ...useQueryProfile.getFetchOptions({ cache: 'no-cache' }),
    queryKey: [key],
  });
