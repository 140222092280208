import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as WaybillTypes from './waybill.types';

//
//

export class ManagerReverseOrders extends ZidderResource<ManagerReverseOrders> {
  protected classInstance = ManagerReverseOrders;
  public pathBase = 'v1/managers/store/reverse-orders/';

  //

  async createWaybill(payload: WaybillTypes.CreateWaybillPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'waybill', {
      json: payload,
      ...options,
    });
    const result = await response.json<WaybillTypes.CreateWaybillResponse>();

    if (!result?.order_reverse) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
