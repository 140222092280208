import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as LateDeliveryTypes from './late-delivery.types';
import * as ListTypes from './list.types';

//
//

export class ManagerZidshipShipments extends ZidderResource<ManagerZidshipShipments> {
  protected classInstance = ManagerZidshipShipments;
  public pathBase = 'v1/managers/store/zidship/shipments';

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result.shipments) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getLateDeliveryStatus(
    query: LateDeliveryTypes.LateDeliveryStatusQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + `/${query.trackingNumber}/late-tags`, {
      ...options,
    });

    const result = await response.json<LateDeliveryTypes.LateDeliveryStatusResponse>();

    if (!result['late-tags']) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
