import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as PagesTypes from './types';

//
//

export class ManagerPages extends ZidderResource<ManagerPages> {
  protected classInstance = ManagerPages;
  public pathBase = 'v1/managers/store/pages/';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });

    const result = await response.json<PagesTypes.PagesGetResponse>();

    if (!result?.store_pages) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(id: string) {
    const response = await this.client.delete(this.pathBase + id);

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async activationToggle(
    payload: PagesTypes.PagesSetActivationPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'activation', {
      ...options,
      json: payload,
    });

    const result = await response.json<PagesTypes.PagesActivationGeneralResponse>();

    if (!result.extra_pages) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async activationGet(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'activation', {
      ...options,
    });

    const result = await response.json<PagesTypes.PagesActivationGeneralResponse>();

    if (!result.extra_pages) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload: PagesTypes.PagesCreatePayload) {
    const response = await this.client.post(this.pathBase, {
      json: payload,
    });

    const result = await response.json<PagesTypes.PagesCreateResponse>();

    if (!result.store_page) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async view(params: PagesTypes.PagesViewParams, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + params.id, {
      ...options,
    });

    const result = await response.json<PagesTypes.PagesViewResponse>();

    if (!result.store_page) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: PagesTypes.PagesUpdatePayload) {
    const response = await this.client.put(this.pathBase + payload.id, {
      json: payload,
    });

    const result = await response.json<PagesTypes.PagesCreateResponse>();

    if (!result.store_page) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
