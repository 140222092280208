import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as BundleOffersTypes from './types';

//
//

export class ManagerBundleOffers extends ZidderResource<ManagerBundleOffers> {
  protected classInstance = ManagerBundleOffers;
  public pathBase = 'v1/managers/store/discounts/bundle-offers/';

  //

  async listBundleOffers(
    query?: BundleOffersTypes.BundleOffersListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<BundleOffersTypes.BundleOffersListResponse>();

    if (!result.discount_rules) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteBundleOffer(
    id: BundleOffersTypes.DeleteBundleOfferPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + id, {
      ...options,
    });
    const result = await response.json<BundleOffersTypes.DeleteBundleOfferResponse>();

    return result;
  }

  async create(
    payload: BundleOffersTypes.CreateBundleOfferPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });
    const result = await response.json<BundleOffersTypes.CreateBundleOfferResponse>();

    if (!result.discount_rule) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async view(payload: BundleOffersTypes.ViewBundleOfferPayload, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + payload.id, {
      ...options,
    });
    const result = await response.json<BundleOffersTypes.ViewBundleOfferResponse>();

    return result;
  }

  async update(
    payload: BundleOffersTypes.CreateBundleOfferPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + payload.id, {
      ...options,
      json: payload,
    });
    const result = await response.json<BundleOffersTypes.UpdateBundleOfferResponse>();

    if (!result.discount_rule) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
