import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

//
//

export class ManagerStorePaymentOptions extends ZidderResource<ManagerStorePaymentOptions> {
  protected classInstance = ManagerStorePaymentOptions;
  public pasePath = 'v1/managers/store/payment-options';

  //

  async list(query?: ListTypes.StorePaymentOptionsListRequest, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pasePath, {
      ...options,
      searchParams: { ...query },
    });
    const result = await response.json<ListTypes.StorePaymentOptionsListResponse>();

    if (!result?.payment_options) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
