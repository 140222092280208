import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';

import * as TransferRequestListTypes from './types';

export class ManagerTransferRequests extends ZidderResource<ManagerTransferRequests> {
  protected classInstance = ManagerTransferRequests;
  public basePath = 'v1/stocks-transfers';

  async list(
    query?: TransferRequestListTypes.TransferRequestQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.get(this.basePath, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<TransferRequestListTypes.TransferRequestResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
