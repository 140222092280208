import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';

import type * as getTypes from './get.types';

export class SettingsDirectApiIntegration extends ZidderResource<SettingsDirectApiIntegration> {
  protected classInstance = SettingsDirectApiIntegration;
  public pathBase = 'v1/managers/account/third-parties/authorization-access-token';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });

    const result = await response.json<getTypes.Response>();

    if (!result.access_token) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
