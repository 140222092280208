import type {} from '@mui/lab/themeAugmentation';
import { Components, CssVarsTheme, Theme } from '@mui/material';

import { MuiAlert } from './alert';
import { MuiAppBar } from './app-bar';
import { MuiAutocomplete } from './autocomplete';
import { MuiBackdrop } from './backdrop';
import { MuiBreadcrumbs } from './breadcrumbs';
import { MuiButton } from './button';
import { MuiCard } from './card';
import { MuiChip } from './chip';
import { MuiDataGrid } from './data-grid';
import { MuiDialog, MuiDialogTitle, MuiDialogContent, MuiDialogActions } from './dialog';
import { MuiDivider } from './divider';
import { MuiDrawer } from './drawer';
import { MuiFormGroup } from './form-group';
import { MuiFormHelperText } from './form-helper-text';
import { MuiFormLabel } from './form-label';
import { MuiIcon } from './icon';
import { MuiIconButton } from './icon-button';
import { MuiInputAdornment } from './input-adornment';
import { MuiInputBase } from './input-base';
import { MuiInputLabel } from './input-label';
import { MuiList, MuiListItemText } from './list';
// import { MuiAppButton } from './loading-button';
import { MuiMenu } from './menu';
import { MuiPagination } from './pagination';
import { MuiPaper } from './paper';
import { MuiRadio } from './radio';
import { MuiRadioGroup } from './radio-group';
import { MuiSelect } from './select';
import { MuiStack } from './stack';
import { MuiSvgIcon } from './svg-icon';
import { MuiSwitch } from './switch';
import { MuiTab } from './tab';
import { MuiTable } from './table';
import { MuiTabs } from './tabs';
import { MuiTextField } from './text-field';
import { MuiTimeline } from './timeline';
import { MuiTypography } from './typography';

//
//

export const components: Components<Omit<Theme, 'components' | 'palette'> & CssVarsTheme> = {
  MuiAutocomplete,
  MuiButton,
  MuiRadio,
  MuiAlert,
  MuiFormGroup,
  MuiAppBar,
  MuiInputAdornment,
  MuiRadioGroup,
  MuiBreadcrumbs,
  MuiTypography,
  MuiChip,
  MuiTextField,
  MuiMenu,
  MuiSelect,
  MuiInputLabel,
  MuiInputBase,
  // MuiAppButton,
  MuiIcon,
  MuiIconButton,
  MuiSwitch,
  MuiFormLabel,
  MuiFormHelperText,
  MuiCard,
  MuiTable,
  MuiTab,
  MuiTabs,
  MuiDrawer,
  MuiBackdrop,
  MuiDivider,
  MuiStack,
  MuiList,
  MuiListItemText,
  MuiPagination,
  MuiDialog,
  MuiDialogTitle,
  MuiDialogContent,
  MuiDialogActions,
  MuiSvgIcon,
  MuiTimeline,

  // MuiTextField: {
  //   styleOverrides: {
  //     root: {
  //       '& .MuiFilledInput-root': {
  //         backgroundColor: '#ffffff',
  //         '&:hover': { backgroundColor: '#ffffff' },
  //         '&.Mui-focused': { backgroundColor: '#ffffff' },
  //         '&:active': { backgroundColor: '#ffffff' },
  //       },
  //       '& label.Mui-focused': { color: 'main' },
  //     },
  //   },
  // },
  // MuiInputLabel: {
  //   styleOverrides: {
  //     outlined: {
  //       zIndex: 1,
  //       pointerEvents: 'none',
  //       transform: 'translate(14px, 16px) scale(1)',
  //       maxWidth: 'calc(100% - 24px)',
  //     },
  //   },
  // },
  MuiPaper,
  // @ts-expect-error TODO: fix typing
  MuiDataGrid,
  MuiFilledInput: {
    styleOverrides: {
      root: {
        borderRadius: 8,
        '&::before, &::after': {
          borderBottom: 0,
        },
        '&:hover:not(.Mui-disabled, .Mui-error):before': {
          borderBottom: 0,
        },
        '&.Mui-focused:after': {
          borderBottom: 0,
        },
      },
    },
  },
};
