import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';

import * as ManagerProductsNGOSearchListTypes from './types';

//
//

export class ManagerProductsNGOSearch extends ZidderResource<ManagerProductsNGOSearch> {
  protected classInstance = ManagerProductsNGOSearch;
  public basePath = 'v1/products/ngo-search';

  async list(query?: ManagerProductsNGOSearchListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.basePath, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ManagerProductsNGOSearchListTypes.NGOSearchResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
