import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';

export class ManagerMazeedDashboardProfileSummary extends ZidderResource<ManagerMazeedDashboardProfileSummary> {
  protected classInstance = ManagerMazeedDashboardProfileSummary;
  public pathBase = 'v1/mazeed/dashboard/profile-summary';

  async get(query?: GetTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<GetTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
