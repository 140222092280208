import { PageErrorHandler } from '~/zidmui/components';
import { MuiDocument } from '~/zidmui/libraries/mui';

//
//

// https://remix.run/docs/en/main/route/error-boundary
export const ErrorBoundary = () => {
  return (
    <MuiDocument>
      <PageErrorHandler />
    </MuiDocument>
  );
};
