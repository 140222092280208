import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { prepareFormDataForMultipleKeys, prepareFormDataForOneFile } from '~/zidderjs/utils';

import * as GetTypes from './get.types';
import * as UpdateTypes from './update.types';

//
//

export class ManagerMobileAppTabSettings extends ZidderResource<ManagerMobileAppTabSettings> {
  protected classInstance = ManagerMobileAppTabSettings;
  public pathBase = 'v1/managers/store/mobile-app-tab-settings/';

  //

  async get(query?: GetTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<GetTypes.Response>();

    if (!result?.settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update(payload: UpdateTypes.Payload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const { mobile_app_icon_file, ...data } = payload;

    const body = prepareFormDataForOneFile({ mobile_app_icon_file }, 'mobile_app_icon_file');

    prepareFormDataForMultipleKeys(data, body);

    const response = await this.client.post(this.pathBase, {
      ...options,
      body: body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<UpdateTypes.Response>();

    if (result?.settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
