import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';

//
//

export class ManagerZidshipCivilId extends ZidderResource<ManagerZidshipCivilId> {
  protected classInstance = ManagerZidshipCivilId;
  public pathBase = 'v1/managers/store/zidship/civil-id';

  async get(query?: GetTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<GetTypes.Response>();

    if (!result.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
