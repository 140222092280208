import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as GetTypes from './get.types';
import type * as UpdateTypes from './update.types';
//
//

export class ManagerStaffNotifications extends ZidderResource<ManagerStaffNotifications> {
  protected classInstance = ManagerStaffNotifications;
  public pathBase = 'v1/managers/store/staff-notifications/settings';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
    });

    const result = await response.json<GetTypes.StaffNotificationsResponse>();

    if (!result?.order_statuses) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update(data: UpdateTypes.UpdateRequest, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      body: JSON.stringify(data),
    });

    const result = await response.json<UpdateTypes.UpdateResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
