import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as GetTypes from './get.types';
import type * as UpdateTypes from './update.types';
//
//

export class ManagerBankInfo extends ZidderResource<ManagerBankInfo> {
  protected classInstance = ManagerBankInfo;
  public pathBase = 'v1/managers/store/bank-info';

  //

  async get(query?: GetTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<GetTypes.Response>();

    if (!result?.bank_info) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: UpdateTypes.Payload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });

    const result = await response.json<UpdateTypes.Response>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
