import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as NotifyCustomerTypes from './list.type';

//
//

export class ManagerNotifyCustomer extends ZidderResource<ManagerNotifyCustomer> {
  protected classInstance = ManagerNotifyCustomer;
  public pathBase = 'v1/products/';

  async list(query?: NotifyCustomerTypes.NotifyCustomerListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};
    const response = await this.client.get(`${this.pathBase}notifications`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<NotifyCustomerTypes.NotifyCustomerListResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getStatistics(
    query?: NotifyCustomerTypes.NotifyCustomerListQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};
    const response = await this.client.get(`${this.pathBase}notifications/stats`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<NotifyCustomerTypes.NotifyCustomerStatisticsResponse>();

    if (!('notified_count' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async sendEmail(
    payload: NotifyCustomerTypes.NotifyCustomerSendRequestPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}notifications/send`, {
      ...options,
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async export(
    payload: NotifyCustomerTypes.NotifyCustomerExportRequestPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}notifications/export`, {
      ...options,
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getSettings(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}notifications/settings`, {
      ...options,
    });

    const result = await response.json<NotifyCustomerTypes.NotifyCustomerSettingsResponse>();

    if (!result?.settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateSettings(
    payload: NotifyCustomerTypes.NotifyCustomerSettingsRequestPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}notifications/settings`, {
      ...options,
      json: payload,
    });

    if (response.status !== 201) {
      this.throwUnexpectedResponse(response);
    }

    return response;
  }
}
