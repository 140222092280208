import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ActivateTypes from './activate.types';
import * as DisableTypes from './disable.types';
import * as ListTypes from './list.types';
import * as SubmitTypes from './submit.types';

export class ManagerMazeedDashboardProducts extends ZidderResource<ManagerMazeedDashboardProducts> {
  protected classInstance = ManagerMazeedDashboardProducts;
  public pathBase = 'v1/mazeed/dashboard/products';

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async activate(query: ActivateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/activate`, {
      ...options,
      json: query,
    });

    const result = await response.json<ActivateTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async disable(query: DisableTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/disable`, {
      ...options,
      json: query,
    });

    const result = await response.json<DisableTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async submit(query: SubmitTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/submit`, {
      ...options,
      json: query,
    });

    const result = await response.json<SubmitTypes.Response>();

    if (!result?.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
