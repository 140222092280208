import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as SubmitTypes from './submit.types';

export class ManagerMazeedDashboardSubmitStoreProducts extends ZidderResource<ManagerMazeedDashboardSubmitStoreProducts> {
  protected classInstance = ManagerMazeedDashboardSubmitStoreProducts;
  public pathBase = 'v1/mazeed/dashboard/submit-store-products';

  async submit(_: SubmitTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
    });

    const result = await response.json<SubmitTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
