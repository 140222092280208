import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as UpdateTypes from './update.types';

//
//

export class ManagerCrNumber extends ZidderResource<ManagerCrNumber> {
  protected classInstance = ManagerCrNumber;
  public pathBase = 'v1/managers/store/cr';

  async update(payload?: UpdateTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(this.pathBase, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<UpdateTypes.Response>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
