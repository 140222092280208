import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as UpdateTypes from './update.types';

//
//

export class ManagerLanguages extends ZidderResource<ManagerLanguages> {
  protected classInstance = ManagerLanguages;

  public pathBase = 'v1/managers/store/';

  async updateDefaultLanguage(
    data: UpdateTypes.UpdateDefaultLanguageRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}default-language`, {
      ...options,
      json: { ...data },
    });

    const result = await response.json<UpdateTypes.UpdateDefaultLanguageResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateLanguages(data: UpdateTypes.UpdateLanguagesRequest, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}languages/`, {
      ...options,
      json: { ...data },
    });

    const result = await response.json<UpdateTypes.UpdateLanguagesResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
