import ky, { KyInstance, Options } from 'ky';
import { KyHeadersInit } from 'node_modules/ky/distribution/types/options';

import { SettingsDirectApiIntegration } from '~/zidderjs/resources/settings/settings-direct-api-integration';

import { Settings, SettingsVat, SettingsTax } from '../resources';
import * as Manager from '../resources/manager';
import { ZidderHeaders } from '../types';
import { zidderGetHeaders, parseErrors } from '../utils';

//
//
export class Zidder {
  protected readonly client: KyInstance;
  protected _headers: KyHeadersInit = {};

  public readonly head: KyInstance['head'];
  public readonly get: KyInstance['get'];
  public readonly post: KyInstance['post'];
  public readonly put: KyInstance['put'];
  public readonly patch: KyInstance['patch'];
  public readonly delete: KyInstance['delete'];

  public readonly managerAuth: Manager.ManagerAuth;
  public readonly managerAccount: Manager.ManagerAccount;
  public readonly managerProducts: Manager.ManagerProducts;
  public readonly managerLocations: Manager.ManagerLocations;
  public readonly managerTicketing: Manager.ManagerTicketing;
  public readonly managerZidpayTransactions: Manager.ManagerZidpayTransactions;

  public readonly managerOrders: Manager.ManagerOrders;
  public readonly managerReverseOrders: Manager.ManagerReverseOrders;
  public readonly managerOrdersDrafts: Manager.ManagerOrdersDrafts;
  public readonly managerOrderNotifications: Manager.ManagerOrderNotifications;
  public readonly managerIdentityDocument: Manager.ManagerIdentityDocument;
  public readonly managerCrNumber: Manager.ManagerCrNumber;

  public readonly managerCustomers: Manager.ManagerCustomers;
  public readonly managerModerators: Manager.ManagerModerators;
  public readonly managerCustomerAddress: Manager.ManagerCustomerAddress;
  public readonly managerCatalog: Manager.ManagerCatalog;
  public readonly managerCategories: Manager.ManagerCategories;
  public readonly managerAbandonedCarts: Manager.ManagerAbandonedCarts;
  public readonly managerZidpayPaymentLinks: Manager.ManagerZidpayPaymentLinks;
  public readonly managerCoupons: Manager.ManagerCoupons;
  public readonly managerAffiliates: Manager.ManagerAffiliates;
  public readonly managerMarket: Manager.ManagerMarket;
  public readonly managerBundleOffers: Manager.ManagerBundleOffers;
  public readonly managerSeo: Manager.ManagerSeo;
  public readonly managerSettingsDomains: Manager.ManagerSettingsDomains;
  public readonly managerSmsCampaign: Manager.ManagerSmsCampaign;
  public readonly managerZidpayDeposits: Manager.ManagerZidpayDeposits;
  public readonly managerZidpayDepositDetails: Manager.ManagerZidpayDepositDetails;
  public readonly managerZidposCashiers: Manager.ManagerZidposCashiers;
  public readonly managerZidposReportsSessions: Manager.ManagerZidposReportsSessions;
  public readonly managerZidposReportsSales: Manager.ManagerZidposReportsSales;
  public readonly managerZidposReportsPayments: Manager.ManagerZidposReportsPayments;
  public readonly managerMetafields: Manager.ManagerMetafields;
  public readonly managerZidposPaymentMethods: Manager.ManagerZidposPaymentMethods;
  public readonly managerAi: Manager.ManagerAi;
  public readonly managerNotifyCustomer: Manager.ManagerNotifyCustomer;
  public readonly managerInventories: Manager.ManagerInventories;
  public readonly managerReviewsNotificationsSettings: Manager.ManagerReviewsNotificationsSettings;
  public readonly managerZidshipInventoryAddress: Manager.ManagerZidshipInventoryAddress;
  public readonly managerZidposOnboarding: Manager.ManagerZidposOnboarding;
  public readonly managerZidshipShipments: Manager.ManagerZidshipShipments;
  public readonly managerZidshipServiceLevels: Manager.ManagerZidshipServiceLevels;
  public readonly managerZidshipOtp: Manager.ManagerZidshipOtp;
  public readonly managerIban: Manager.ManagerIban;
  public readonly managerZidshipPrepaidPackagesReports: Manager.ManagerZidshipPrepaidPackagesReports;
  public readonly managerZidshipPrepaidPackages: Manager.ManagerZidshipPrepaidPackages;
  public readonly managerZidshipCivilId: Manager.ManagerZidshipCivilId;
  public readonly managerZidshipConsignorContactInfo: Manager.ManagerZidshipConsignorContactInfo;
  public readonly managerZidshipCommercialRegistrationInfo: Manager.ManagerZidshipCommercialRegistrationInfo;
  public readonly managerSettingsOrderProducts: Manager.ManagerSettingsOrderProducts;
  public readonly managerSettingsStoreAboutInformation: Manager.ManagerSettingsStoreAboutInformation;
  public readonly managerSettingsInformationStoreBusiness: Manager.ManagerSettingsInformationStoreBusiness;
  public readonly managerSettingsInformationStoreKYC: Manager.ManagerSettingsInformationStoreKYC;
  public readonly managerSettingsCommunicationsQuestions: Manager.ManagerSettingsCommunicationsQuestions;
  public readonly managerStoreSettings: Manager.ManagerStoreSettings;
  public readonly managerStorePaymentOptions: Manager.ManagerStorePaymentOptions;
  public readonly managerStoreShippingMethods: Manager.ManagerStoreShippingMethods;
  public readonly managerFAQs: Manager.ManagerFAQs;
  public readonly managerSettingsGifting: Manager.ManagerSettingsGifting;
  public readonly managerSettingsTeam: Manager.ManagerSettingsTeam;
  public readonly managerCheckoutConstraints: Manager.ManagerCheckoutConstraints;
  public readonly managerScheduledNotificationsSettings: Manager.ManagerScheduledNotificationsSettings;
  public readonly managerCurrencies: Manager.ManagerCurrencies;
  public readonly managerLanguages: Manager.ManagerLanguages;
  public readonly managerLoyaltyProgram: Manager.ManagerLoyaltyProgram;
  public readonly managerLoyaltyProgramInfo: Manager.ManagerLoyaltyProgramInfo;
  public readonly managerStaffNotifications: Manager.ManagerStaffNotifications;
  public readonly managerReturnPolicy: Manager.ManagerReturnPolicy;
  public readonly managerTermsCondition: Manager.ManagerTermsCondition;
  public readonly managerLicenses: Manager.ManagerLicenses;
  public readonly managerExchangeReturns: Manager.ManagerExchangeReturns;
  public readonly managerComplaintsSuggestions: Manager.ManagerComplaintsSuggestions;
  public readonly managerPrivacyPolicy: Manager.ManagerPrivacyPolicy;
  public readonly managerStockTakings: Manager.ManagerStockTakings;
  public readonly managerAutomaticDiscounts: Manager.ManagerAutomaticDiscounts;
  public readonly managerTransferRequests: Manager.ManagerTransferRequests;
  public readonly managerMazeedDashboardProducts: Manager.ManagerMazeedDashboardProducts;
  public readonly managerResellersSettlements: Manager.ManagerResellersSettlements;

  public readonly managerMazeedDashboardProfileSummary: Manager.ManagerMazeedDashboardProfileSummary;
  public readonly managerMazeedDashboardSubmitStoreProducts: Manager.ManagerMazeedDashboardSubmitStoreProducts;
  public readonly managerBanks: Manager.ManagerBanks;
  public readonly managerBankInfo: Manager.ManagerBankInfo;
  public readonly managerSettingsBanks: Manager.ManagerSettingsBanks;
  public readonly settings: Settings;

  public readonly settingsTax: SettingsTax;
  public readonly settingsVat: SettingsVat;
  public readonly settingsDirectApiIntegration: SettingsDirectApiIntegration;
  public readonly managerSettingsWorktime: Manager.ManagerSettingsWorktime;
  public readonly managerZidpay: Manager.ManagerZidpay;
  public readonly managerAppMarket: Manager.ManagerAppMarket;
  public readonly managerPages: Manager.ManagerPages;
  public readonly managerProductsNGOSearch: Manager.ManagerProductsNGOSearch;
  public readonly managerSMSCampaignFoster: Manager.ManagerSMSCampaignFoster;
  public readonly managerCouponsReport: Manager.ManagerCouponsReport;
  public readonly managerMobileAppTabSettings: Manager.ManagerMobileAppTabSettings;

  constructor(
    protected readonly options: Options,
    zidderHeaders?: ZidderHeaders,
  ) {
    if (!this.options?.headers) this.options.headers = {};
    if (!this.options?.hooks) this.options.hooks = {};
    if (!this.options?.hooks?.beforeRequest?.length) this.options.hooks.beforeRequest = [];
    if (!this.options?.hooks?.beforeError?.length) this.options.hooks.beforeError = [];

    this.options.hooks.beforeRequest.push((req, _options) => {
      (_options as Options & { _startTime: number })._startTime = new Date().getTime();

      Object.entries(this._headers).map(([key, value]) => {
        if (!req.headers.has(key)) req.headers.set(key, value);
      });
    });

    this.options.hooks.beforeError.push(error => {
      throw parseErrors(error);
    });

    this._headers = {
      'accept-language': 'en',
      ...this.options?.headers,
      ...zidderGetHeaders(zidderHeaders),
    };

    this.client = ky.create(this.options);

    this.head = this.client.head.bind(this.client);
    this.get = this.client.get.bind(this.client);
    this.post = this.client.post.bind(this.client);
    this.put = this.client.put.bind(this.client);
    this.patch = this.client.patch.bind(this.client);
    this.delete = this.client.delete.bind(this.client);

    this.managerAuth = new Manager.ManagerAuth(this.client);
    this.managerAccount = new Manager.ManagerAccount(this.client);
    this.managerProducts = new Manager.ManagerProducts(this.client);
    this.managerTicketing = new Manager.ManagerTicketing(this.client);
    this.managerZidpayTransactions = new Manager.ManagerZidpayTransactions(this.client);

    this.managerOrders = new Manager.ManagerOrders(this.client);
    this.managerReverseOrders = new Manager.ManagerReverseOrders(this.client);
    this.managerOrdersDrafts = new Manager.ManagerOrdersDrafts(this.client);
    this.managerOrderNotifications = new Manager.ManagerOrderNotifications(this.client);

    this.managerBanks = new Manager.ManagerBanks(this.client);
    this.managerBankInfo = new Manager.ManagerBankInfo(this.client);
    this.managerCustomers = new Manager.ManagerCustomers(this.client);
    this.managerIban = new Manager.ManagerIban(this.client);
    this.managerModerators = new Manager.ManagerModerators(this.client);
    this.managerCustomerAddress = new Manager.ManagerCustomerAddress(this.client);
    this.managerAbandonedCarts = new Manager.ManagerAbandonedCarts(this.client);
    this.managerZidpayPaymentLinks = new Manager.ManagerZidpayPaymentLinks(this.client);
    this.managerAffiliates = new Manager.ManagerAffiliates(this.client);
    this.managerBundleOffers = new Manager.ManagerBundleOffers(this.client);
    this.managerSeo = new Manager.ManagerSeo(this.client);
    this.managerSettingsDomains = new Manager.ManagerSettingsDomains(this.client);
    this.managerSmsCampaign = new Manager.ManagerSmsCampaign(this.client);
    this.managerCatalog = new Manager.ManagerCatalog(this.client);
    this.managerCategories = new Manager.ManagerCategories(this.client);
    this.managerZidpayDeposits = new Manager.ManagerZidpayDeposits(this.client);
    this.managerCoupons = new Manager.ManagerCoupons(this.client);
    this.managerIdentityDocument = new Manager.ManagerIdentityDocument(this.client);
    this.managerCrNumber = new Manager.ManagerCrNumber(this.client);
    this.managerMarket = new Manager.ManagerMarket(this.client);
    this.managerZidpayDepositDetails = new Manager.ManagerZidpayDepositDetails(this.client);
    this.managerZidposCashiers = new Manager.ManagerZidposCashiers(this.client);
    this.managerZidposReportsSessions = new Manager.ManagerZidposReportsSessions(this.client);
    this.managerZidposReportsSales = new Manager.ManagerZidposReportsSales(this.client);
    this.managerZidposReportsPayments = new Manager.ManagerZidposReportsPayments(this.client);
    this.managerNotifyCustomer = new Manager.ManagerNotifyCustomer(this.client);
    this.managerMetafields = new Manager.ManagerMetafields(this.client);
    this.managerInventories = new Manager.ManagerInventories(this.client);
    this.managerLocations = new Manager.ManagerLocations(this.client);
    this.managerZidshipInventoryAddress = new Manager.ManagerZidshipInventoryAddress(this.client);
    this.managerZidposOnboarding = new Manager.ManagerZidposOnboarding(this.client);
    this.managerZidshipCivilId = new Manager.ManagerZidshipCivilId(this.client);
    this.managerZidshipConsignorContactInfo = new Manager.ManagerZidshipConsignorContactInfo(
      this.client,
    );
    this.managerZidshipCommercialRegistrationInfo =
      new Manager.ManagerZidshipCommercialRegistrationInfo(this.client);
    this.managerZidshipShipments = new Manager.ManagerZidshipShipments(this.client);
    this.managerZidshipServiceLevels = new Manager.ManagerZidshipServiceLevels(this.client);
    this.managerZidshipPrepaidPackagesReports = new Manager.ManagerZidshipPrepaidPackagesReports(
      this.client,
    );
    this.managerZidshipOtp = new Manager.ManagerZidshipOtp(this.client);
    this.managerSettingsOrderProducts = new Manager.ManagerSettingsOrderProducts(this.client);
    this.managerSettingsStoreAboutInformation = new Manager.ManagerSettingsStoreAboutInformation(
      this.client,
    );
    this.managerSettingsInformationStoreBusiness =
      new Manager.ManagerSettingsInformationStoreBusiness(this.client);
    this.managerSettingsInformationStoreKYC = new Manager.ManagerSettingsInformationStoreKYC(
      this.client,
    );
    this.managerSettingsCommunicationsQuestions =
      new Manager.ManagerSettingsCommunicationsQuestions(this.client);
    this.managerFAQs = new Manager.ManagerFAQs(this.client);
    this.settingsTax = new SettingsTax(this.client);
    this.settingsVat = new SettingsVat(this.client);
    this.settingsDirectApiIntegration = new SettingsDirectApiIntegration(this.client);
    this.managerReviewsNotificationsSettings = new Manager.ManagerReviewsNotificationsSettings(
      this.client,
    );
    this.managerCurrencies = new Manager.ManagerCurrencies(this.client);
    this.managerLanguages = new Manager.ManagerLanguages(this.client);
    this.managerStorePaymentOptions = new Manager.ManagerStorePaymentOptions(this.client);
    this.managerStoreShippingMethods = new Manager.ManagerStoreShippingMethods(this.client);
    this.managerZidposPaymentMethods = new Manager.ManagerZidposPaymentMethods(this.client);
    this.managerAi = new Manager.ManagerAi(this.client);
    this.managerStoreSettings = new Manager.ManagerStoreSettings(this.client);
    this.managerSettingsGifting = new Manager.ManagerSettingsGifting(this.client);
    this.managerCheckoutConstraints = new Manager.ManagerCheckoutConstraints(this.client);
    this.managerScheduledNotificationsSettings = new Manager.ManagerScheduledNotificationsSettings(
      this.client,
    );
    this.managerZidshipPrepaidPackages = new Manager.ManagerZidshipPrepaidPackages(this.client);
    this.managerStaffNotifications = new Manager.ManagerStaffNotifications(this.client);
    this.settings = new Settings(this.client);
    this.managerSettingsTeam = new Manager.ManagerSettingsTeam(this.client);
    this.managerSettingsWorktime = new Manager.ManagerSettingsWorktime(this.client);
    this.managerZidpay = new Manager.ManagerZidpay(this.client);
    this.managerLoyaltyProgram = new Manager.ManagerLoyaltyProgram(this.client);
    this.managerLoyaltyProgramInfo = new Manager.ManagerLoyaltyProgramInfo(this.client);
    this.managerPages = new Manager.ManagerPages(this.client);
    this.managerReturnPolicy = new Manager.ManagerReturnPolicy(this.client);
    this.managerTermsCondition = new Manager.ManagerTermsCondition(this.client);
    this.managerLicenses = new Manager.ManagerLicenses(this.client);
    this.managerExchangeReturns = new Manager.ManagerExchangeReturns(this.client);
    this.managerComplaintsSuggestions = new Manager.ManagerComplaintsSuggestions(this.client);
    this.managerPrivacyPolicy = new Manager.ManagerPrivacyPolicy(this.client);
    this.managerStockTakings = new Manager.ManagerStockTakings(this.client);
    this.managerTransferRequests = new Manager.ManagerTransferRequests(this.client);
    this.managerSettingsBanks = new Manager.ManagerSettingsBanks(this.client);
    this.managerAppMarket = new Manager.ManagerAppMarket(this.client);
    this.managerAutomaticDiscounts = new Manager.ManagerAutomaticDiscounts(this.client);
    this.managerProductsNGOSearch = new Manager.ManagerProductsNGOSearch(this.client);
    this.managerSMSCampaignFoster = new Manager.ManagerSMSCampaignFoster(this.client);
    this.managerMazeedDashboardProducts = new Manager.ManagerMazeedDashboardProducts(this.client);
    this.managerResellersSettlements = new Manager.ManagerResellersSettlements(this.client);
    this.managerCouponsReport = new Manager.ManagerCouponsReport(this.client);
    this.managerMobileAppTabSettings = new Manager.ManagerMobileAppTabSettings(this.client);
    this.managerMazeedDashboardProducts = new Manager.ManagerMazeedDashboardProducts(this.client);
    this.managerMazeedDashboardProfileSummary = new Manager.ManagerMazeedDashboardProfileSummary(
      this.client,
    );
    this.managerMazeedDashboardSubmitStoreProducts =
      new Manager.ManagerMazeedDashboardSubmitStoreProducts(this.client);
  }

  public setHeadersGolbally(headers: ZidderHeaders) {
    this._headers = { ...this._headers, ...zidderGetHeaders(headers) };
  }
}
