import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as StoreSettingsTypes from './types';

//
//

export class ManagerStoreSettings extends ZidderResource<ManagerStoreSettings> {
  protected classInstance = ManagerStoreSettings;
  public pathBase = 'v1/managers/store/settings/';

  //

  async settings(params: StoreSettingsTypes.StoreSettingsParams, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...params },
    });

    const result = await response.json<StoreSettingsTypes.StoreSettingsResponse>();

    if (!('payload' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateSettings(
    payload: StoreSettingsTypes.StoreSettingsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });

    const result = await response.json<StoreSettingsTypes.StoreSettingsResponse>();

    if (!('payload' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
