import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as DeleteTypes from './delete-theme.types';
import * as GetTypes from './get-theme.types';
import * as TestimonialsListTypes from './list-testimonials.types';
import * as CustomThemeListTypes from './list-theme-custom-themes.types';
import * as ThemesStoreListTypes from './list-theme-store.types';
import * as ListTypes from './list-theme.types';
import * as PostTypes from './post-theme.types';
import * as PutTypes from './put-theme.types';

//
//

export class ManagerMarket extends ZidderResource<ManagerMarket> {
  protected classInstance = ManagerMarket;
  public pathBase = 'v1/managers/store/themes-store/';
  public customThemePathBase = 'v1/managers/store/storefront-themes/';
  public partnersPathBase = 'v1/market/md/testimonials/';
  public landingPageToken = 'v1/managers/store/landing-page/token';

  //

  async partnersTestimonialsList(
    query?: TestimonialsListTypes.ListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.partnersPathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<TestimonialsListTypes.Response>();

    if (!result.testimonials) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async themesSectionsList(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'sections', {
      ...options,
    });
    const result = await response.json<ListTypes.SectionsListResponse>();

    if (!result?.sections) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async ownedThemesList(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'owned', {
      ...options,
    });
    const result = await response.json<ListTypes.OwnedListResponse>();

    if (!result?.themes) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async publicThemesList(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });
    const result = await response.json<ThemesStoreListTypes.ThemesStoreResponse>();

    if (!result?.themes) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async obtainedPublicThemesList(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'obtained', {
      ...options,
    });
    const result = await response.json<ThemesStoreListTypes.ThemesStoreResponse>();

    if (!result?.themes) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async customThemesList(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.customThemePathBase, {
      ...options,
    });
    const result = await response.json<CustomThemeListTypes.Response>();

    if (!result?.themes) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getThemeEditorToken(options?: ZidderRequestOptions) {
    const response = await this.client.post(this.landingPageToken, {
      ...options,
    });
    const result = await response.json<GetTypes.EditorTokenResponse>();

    return result;
  }

  async createCustomTheme(
    data: PostTypes.CreateCustomThemePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.customThemePathBase, {
      ...options,
      json: { ...data },
    });
    const result = await response.json<PostTypes.CreateCustomThemeRespose>();

    return result;
  }

  async updateCustomTheme(
    { id, ...data }: PostTypes.UpdateCustomThemePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.customThemePathBase + id, {
      ...options,
      json: { ...data },
    });
    const result = await response.json<PostTypes.UpdateCustomThemeRespose>();

    return result;
  }

  async activateCustomTheme(
    { id, themeCode }: PutTypes.ActivateCustomThemePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.customThemePathBase + id + '/activate', {
      ...options,
      json: { themeCode },
    });
    const result = await response.json<PutTypes.ActivateCustomThemeRespose>();

    return result;
  }

  async deactivateCustomTheme(
    { id }: PutTypes.DeactivateCustomThemePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.customThemePathBase + id + '/deactivate', {
      ...options,
    });
    const result = await response.json<PutTypes.DeactivateCustomThemeRespose>();

    return result;
  }

  async deleteCustomTheme(
    { id }: DeleteTypes.DeleteCustomThemePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.customThemePathBase + id, {
      ...options,
    });
    const result = await response.json<DeleteTypes.DeleteCustomThemeRespose>();

    return result;
  }
}
