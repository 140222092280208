import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as DeleteTypes from './delete.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as UpdateTypes from './update.types';

//
//

export class ManagerOrderNotifications extends ZidderResource<ManagerOrderNotifications> {
  protected classInstance = ManagerOrderNotifications;

  public pathBase = 'v1/managers/store/order-notifications/';

  //

  async getNotificationsSettings(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}order-statuses`, {
      ...options,
    });

    const result = await response.json<GetTypes.OrderNotificationsResponse>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async listNotificationsMessages(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}list-with-default`, {
      ...options,
    });

    const result = await response.json<ListTypes.OrderNotificationsListResponse>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateNotificationsStatuses(
    data: UpdateTypes.OrderNotificationsStatusesUpdateRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}order-statuses/`, {
      ...options,
      json: data,
    });
    const result = await response.json<GetTypes.OrderNotificationsResponse>();

    if (!result?.message) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateNotificationsMessage(
    data: UpdateTypes.OrderNotificationsMessagesUpdateRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      json: data,
    });

    const result = await response.json<UpdateTypes.OrderNotificationsMessagesUpdateResponse>();

    if (!result?.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deleteNotificationsMessage(
    { status }: DeleteTypes.OrderNotificationsMessageDeleteRequest,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(`${this.pathBase}${status}`, {
      ...options,
    });

    const result = await response.json<DeleteTypes.OrderNotificationsMessageDeleteResponse>();

    if (!result?.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deleteNotificationsMessages(options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}`, {
      ...options,
    });

    const result = await response.json<DeleteTypes.OrderNotificationsMessagesDeleteResponse>();

    if (!result?.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
