import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as LegalPageTypes from './types';

//
//

export class ManagerComplaintsSuggestions extends ZidderResource<ManagerComplaintsSuggestions> {
  protected classInstance = ManagerComplaintsSuggestions;
  public pathBase = 'v1/managers/store/complaints-suggestions';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });

    const result = await response.json<LegalPageTypes.ComplaintsAndSuggestionsViewResponse>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    payload: LegalPageTypes.UpdateComplaintsAndSuggestionsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });

    const result = await response.json<LegalPageTypes.UpdateGeneralResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
