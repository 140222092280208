import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as CreateTypes from './create.types';
import type * as GetTypes from './get.types';
import type * as ListTypes from './list.types';
import type * as UpdateTypes from './update.types';

//
//

export class ManagerBanks extends ZidderResource<ManagerBanks> {
  protected classInstance = ManagerBanks;
  public pathBase = 'v1/managers/store/banks';

  //

  async get(query: GetTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}/${query.id}/view`, {
      ...options,
    });

    const result = await response.json<GetTypes.Response>();

    if (!result.store_bank) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.store_banks) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: UpdateTypes.Payload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.put(`${this.pathBase}/${payload.id}/update`, {
      ...options,
      json: payload,
    });

    const result = await response.json<UpdateTypes.Response>();

    if (!result.store_bank) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload: CreateTypes.Payload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(this.pathBase + 'add', {
      json: payload,
      ...options,
    });

    const result = await response.json<CreateTypes.Response>();

    if (!result.store_bank) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
