import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { prepareFormDataForOneFile } from '~/zidderjs/utils';

import type * as CreateTypes from './create.types';
import type * as DeleteTypes from './delete.types';
import type * as GetTypes from './get.types';
import type * as UpdateTypes from './update.types';

//
//

export class SettingsTax extends ZidderResource<SettingsTax> {
  protected classInstance = SettingsTax;
  public pathBase = 'v1/managers/store/taxes';

  //
  async create(data: CreateTypes.Query, options?: ZidderRequestOptions) {
    const body = prepareFormDataForOneFile<Partial<CreateTypes.Query>>(
      data,
      'tax_registration_certificate',
      true,
    );
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<CreateTypes.Response>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update(data: UpdateTypes.Query, options?: ZidderRequestOptions) {
    const body = prepareFormDataForOneFile<Partial<UpdateTypes.Query>>(
      data,
      'tax_registration_certificate',
      true,
    );
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<UpdateTypes.Response>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async delete(data: DeleteTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}/${data.id}`, {
      ...options,
    });

    const result = await response.json<DeleteTypes.Response>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get({ id }: GetTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}/${id}`, {
      ...options,
    });

    const result = await response.json<GetTypes.Response>();

    if (!result?.country_tax_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
