import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';

import * as ManagerProductNGOCreateTypes from './create.types';

//
//

export class ManagerSMSCampaignFoster extends ZidderResource<ManagerSMSCampaignFoster> {
  protected classInstance = ManagerSMSCampaignFoster;
  public basePath = 'v1/managers/store/sms-campaigns/foster';

  async update(
    payload: ManagerProductNGOCreateTypes.CreateNGOPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.basePath, {
      ...options,
      json: payload,
    });

    const result = await response.json<ManagerProductNGOCreateTypes.CreateNGOResponse>();

    if (!result.smsCampaign.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
