import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as AppMarketTypes from './types';

//
//

export class ManagerAppMarket extends ZidderResource<ManagerAppMarket> {
  protected classInstance = ManagerAppMarket;
  public pathBase = 'v1/managers/market/apps';

  //

  async get(query: AppMarketTypes.GetQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query },
    });
    const result = await response.json<AppMarketTypes.AppMarketResponseGet>();

    if (!result.applications) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
