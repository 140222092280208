import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as CouponsTypes from './list.types';

//
//

export class ManagerCoupons extends ZidderResource<ManagerCoupons> {
  protected classInstance = ManagerCoupons;
  public pathBase = 'v1/managers/store/';

  async list(query?: CouponsTypes.CouponsListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}coupons`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<CouponsTypes.CouponsListResponse>();

    if (!result?.coupons) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateStatus(
    payload?: CouponsTypes.CouponsUpdateStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.put(`${this.pathBase}coupon/${payload?.coupon_id}/status`, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<CouponsTypes.CouponDetailsResponse>();

    if (!result?.coupon?.coupon_id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async viewDetails(query?: CouponsTypes.CouponViewDetailsPayload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}coupons/${query?.id}/view`, {
      ...options,
    });

    const result = await response.json<CouponsTypes.CouponDetailsResponse>();

    if (!result?.coupon?.coupon_id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    payload?: CouponsTypes.CouponItem | CouponsTypes.CouponItemForm,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.post(
      `${this.pathBase}coupons/${payload?.coupon_id}/update`,
      {
        ...options,
        json: { ...payload },
      },
    );

    const result = await response.json<CouponsTypes.CouponDetailsResponse>();

    if (!result?.coupon?.coupon_id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(payload?: CouponsTypes.CouponViewDetailsPayload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.delete(`${this.pathBase}coupons/${payload?.id}`, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<CouponsTypes.CouponDeleteResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async note(payload?: CouponsTypes.CouponAddNotePayload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.put(`${this.pathBase}coupon/${payload?.id}/note`, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<CouponsTypes.CouponDeleteResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload?: CouponsTypes.CouponItemForm, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}coupons/add`, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<CouponsTypes.CouponDetailsResponse>();

    if (!result?.coupon?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createBulk(payload?: CouponsTypes.CouponItemForm, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}coupons/bulk-add`, {
      ...options,
      json: { ...payload },
    });

    const result = await response.json<CouponsTypes.CouponCreateBulkResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
