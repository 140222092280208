import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as UpdateTypes from './update.types';

//
//

export class ManagerZidshipInventoryAddress extends ZidderResource<ManagerZidshipInventoryAddress> {
  protected classInstance = ManagerZidshipInventoryAddress;
  public pathBase = 'v1/managers/store/zidship/inventory-address';

  async update(query?: UpdateTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<UpdateTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
