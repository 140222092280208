import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { zidderGetHeaders } from '~/zidderjs/utils';

import * as CashiersTypes from './get.types';

//
//

export class ManagerZidposCashiers extends ZidderResource<ManagerZidposCashiers> {
  protected classInstance = ManagerZidposCashiers;
  public pathBase = 'v1/pos/cashiers';

  async list(query?: CashiersTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<CashiersTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
