import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as PostTypes from './post.types';

//
//

export class ManagerStockTakings extends ZidderResource<ManagerStockTakings> {
  protected classInstance = ManagerStockTakings;
  public pathBase = 'v1/stocktakings';

  async list(query?: ListTypes.ListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.ListResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get({ id }: GetTypes.DetailsQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.pathBase}/${id}`, {
      ...options,
    });

    const result = await response.json<GetTypes.DetailsResponse>();

    if (!result?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async create(data: PostTypes.CreatePayload, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(this.pathBase, {
      json: { ...data },
      ...options,
    });

    const result = await response.json<PostTypes.CreateResponse>();

    if (!result?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async listProducts(
    { id, cursor, ...query }: ListTypes.ProductsListQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};
    if (!cursor) cursor = '';

    const response = await this.client.get(`${this.pathBase}/${id}/items/`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams, cursor },
    });

    const result = await response.json<ListTypes.ProductsListResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async exportProducts({ id }: PostTypes.ExportQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathBase}/${id}/export_counts`, {
      ...options,
    });

    const result = await response.json<PostTypes.ExportResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
