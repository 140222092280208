import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as SuggestTypes from './types';

//
//

export class ManagerAi extends ZidderResource<ManagerAi> {
  protected classInstance = ManagerAi;
  public pathBase = 'v1/managers/ai/';

  //

  async suggest(data: SuggestTypes.SuggestPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'suggest', {
      json: { ...data },
      ...options,
    });
    const result = await response.json<SuggestTypes.AiResponse>();

    if (result?.status !== 'pending') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async request(data: SuggestTypes.RequestPayload, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'requests/' + data.id, {
      ...options,
    });
    const result = await response.json<SuggestTypes.AiResponse>();

    if (result?.status !== 'pending' && result?.status !== 'completed') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async translate(data: SuggestTypes.TranslatePayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'translate', {
      json: { ...data },
      ...options,
    });
    const result = await response.json<SuggestTypes.AiResponse>();

    if (result?.status !== 'pending' && result?.status !== 'completed') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
