import { isRouteErrorResponse, useRouteError } from '@remix-run/react';
import { captureRemixErrorBoundaryError } from '@sentry/remix';

import { Box } from '@mui/material';

import { AppErrorIncorrectId, AppErrorNotFound } from '../errors';

import { AppTypography } from './app-typography';

//
//

export const PageErrorHandler = () => {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);

  if (isRouteErrorResponse(error))
    return (
      <>
        <AppTypography variant="h3">
          Error {error?.status} - {error.statusText}
        </AppTypography>

        {error?.data && <AppTypography variant="body1">{error?.data}</AppTypography>}
      </>
    );

  //

  if (error instanceof AppErrorNotFound)
    return (
      <>
        <AppTypography variant="h5">Oops.. Content not found..</AppTypography>
        <AppTypography variant="body1">Please check the page url & item id..</AppTypography>
      </>
    );

  //

  if (error instanceof AppErrorIncorrectId)
    return (
      <>
        <AppTypography variant="h5">Oops.. You entered incorrect item id.</AppTypography>
        <AppTypography variant="body1">
          Please double check and use you&apos;re using the correct item id..
        </AppTypography>
      </>
    );

  // TODO: only show this error in development
  if (error instanceof Error)
    return (
      <>
        <AppTypography variant="h5">
          {error?.message || error?.name || 'Unknown Error'}
        </AppTypography>
        {error?.stack && (
          <Box mt={3}>
            <AppTypography variant="caption" color="textTertiary">
              {error?.stack}
            </AppTypography>
          </Box>
        )}
      </>
    );

  return (
    <>
      <AppTypography variant="h5">Oops.. Something went wrong.</AppTypography>
      <AppTypography variant="body2">
        Please try to reload the page in a few moments..
      </AppTypography>
    </>
  );
};
