/* eslint-disable max-lines */
import { SearchParamsOption } from 'ky';

import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as GetTypes from './get.types';
import * as GroupTypes from './groups.types';
import * as ListPrimaryDataTypes from './list-primary-data.types';
import * as ListTypes from './list.types';
import * as QuestionsTypes from './questions.types';
import * as ReviewsTypes from './reviews.types';

//
//

export class ManagerCustomers extends ZidderResource<ManagerCustomers> {
  protected classInstance = ManagerCustomers;
  public mangersPath = 'v1/managers/';
  public pathBase = `${this.mangersPath}store/`;
  public pathListPrimaryData = `${this.pathBase}customers/` + 'primary-data-list/';

  public pathGroups = 'v1/managers/store/groups/';
  public pathGroupNameAvailability = this.pathGroups + 'name-availability';

  public pathQuestions = 'v1/questions/';

  public pathReviews = 'v1/managers/store/reviews/';

  //

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    const searchParams = new URLSearchParams();

    Object.entries({ ...query, ...options?.searchParams }).forEach(([key, value]) => {
      if (value === undefined) return;

      if (['countries_ids', 'cities_ids'].includes(key)) {
        Array.isArray(value)
          ? value?.map(value2 => searchParams.append(`${key}[]`, String(value2)))
          : searchParams.append(`${key}[]`, String(value));
        // do not set default 'all' to URL params
      } else if (value !== 'all') {
        searchParams.set(key, String(value));
      }
    });

    const response = await this.client.get(`${this.pathBase}customers`, {
      ...options,
      searchParams,
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async listPrimaryData(query?: ListPrimaryDataTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathListPrimaryData, {
      ...options,
      searchParams: {
        page: query?.page,
        perPage: query?.per_page,
        search_term: query?.search_term,
        ...options?.searchParams,
      } as SearchParamsOption,
    });
    const result = await response.json<ListPrimaryDataTypes.Response>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(id: string | number, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}customers/` + id, options);
    const result = await response.json<GetTypes.Response>();

    if (!result?.customer?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  // Groups
  async listGroups(query?: GroupTypes.ListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroups, {
      ...options,
      searchParams: {
        ...query,
        ...options?.searchParams,
      } as SearchParamsOption,
    });

    const result = await response.json<GroupTypes.ResponseGroups>();

    if (!result?.customer_groups) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createGroup(data: GroupTypes.CreateBody, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathGroups, {
      ...options,
      json: data,
    });

    const result = await response.json<GroupTypes.CreateResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async editGroup(id: string, data: GroupTypes.EditBody, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathGroups + id, {
      ...options,
      json: data,
    });

    const result = await response.json<GroupTypes.EditResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getGroup(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroups + id, options);
    const result = await response.json<GroupTypes.GetResponse>();

    if (!result?.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteGroup(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathGroups + id, options);
    const result = await response.json<GroupTypes.DeleteResponse>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async checkNameAvailability(name: string, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathGroupNameAvailability, {
      ...options,
      searchParams: { name },
    });

    const result = await response.json<GroupTypes.NameAvailabilityResponse>();

    if (!result?.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  // Questions

  async listQuestions(query?: QuestionsTypes.ListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathQuestions, {
      ...options,
      searchParams: { ...query, ...options?.searchParams } as SearchParamsOption,
    });

    const result = await response.json<QuestionsTypes.ListResponse>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getCustomerProfile(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}customers/${id}/profile`, options);
    const result = await response.json<ListTypes.CustomerProfileResponse>();

    if (!result?.customer.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  // Reviews

  async listReviews(
    { type, ...query }: ReviewsTypes.ReviewsListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathReviews + type, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ReviewsTypes.ReviewsListResponse>();

    if (!result.reviews) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateCustomerNote(
    id: string | number,
    data: Record<string, string>,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(`${this.pathBase}customer/${id}/note`, {
      ...options,
      json: data,
    });
    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateReview(
    { id, data }: ReviewsTypes.UpdateReviewPayload,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};

    const response = await this.client.post(`${this.pathReviews}${id}/status`, {
      ...options,
      json: data,
    });

    const result = await response.json<ReviewsTypes.UpdateReviewResponse>();

    if (!result.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async customerPointsHistory(id: string | number, options?: ZidderRequestOptions) {
    const response = await this.client.get(
      `${this.mangersPath}loyalty-program/points-history/${id}`,
      {
        ...options,
      },
    );
    const result = await response.json<ListTypes.CustomerPointHistoryResponse>();

    if (result?.status !== 'object') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateCustomerCOD(
    payload: ListTypes.CustomerSettingsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}customers/${payload.id}/cod-status`, {
      ...options,
      json: { status: payload.status },
    });
    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateCustomerNewsLetter(
    payload: ListTypes.CustomerSettingsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(
      `${this.pathBase}customers/${payload.id}/news-letter-status`,
      {
        ...options,
        json: { status: payload.status },
      },
    );
    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateCustomerStatus(
    payload: ListTypes.CustomerSettingsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}customers/${payload.id}/status`, {
      ...options,
      json: { status: payload.status },
    });
    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteCustomer(id: string | number, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}customers/${id}`, options);
    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateCustomer(payload: ListTypes.CustomerUpdatePayload, options?: ZidderRequestOptions) {
    const response = await this.client.put(`${this.pathBase}customers/${payload.id}`, {
      ...options,
      json: payload,
    });
    const result = await response.json<ListTypes.CustomerProfileResponse>();

    if (!result?.customer.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteReview(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathReviews + id, {
      ...options,
    });
    const result = await response.json<ReviewsTypes.DeleteReviewResponse>();

    return result;
  }
}
