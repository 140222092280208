import { getRemoteConfig, fetchAndActivate, RemoteConfigSettings } from 'firebase/remote-config';

import { firebaseApp } from './firebase.client';

export type FeatureFlagKey = 'f_awesomeModeEnabled' | 'f_appEnabled';
export type ConstantKey = 'mazeedMarketplaceResellerId';

export type RemoteConfigKey = FeatureFlagKey | ConstantKey;

const DEFAULT_CONFIG = {
  f_awesomeModeEnabled: true,
  f_appEnabled: true,
  mazeedMarketplaceResellerId: import.meta.env.VITE_MAZEED_MARKETPLACE_RESELLER_ID,
};

const SETTINGS: RemoteConfigSettings = {
  minimumFetchIntervalMillis: Number(import.meta.env.VITE_REMOTE_CONFIG_CACHE_TIME_MS) || 900000,
  fetchTimeoutMillis: Number(import.meta.env.VITE_REMOTE_CONFIG_TIMEOUT_MS) || 10000,
};

// Initialize Remote Config once
const remoteConfig = getRemoteConfig(firebaseApp);

// Set default values and settings
remoteConfig.defaultConfig = { ...DEFAULT_CONFIG };
remoteConfig.settings = { ...SETTINGS };

// Singleton fetch promise
let fetchPromise: Promise<boolean> | null = null;

const fetchAndActivateOnce = (): Promise<boolean> => {
  if (!fetchPromise) {
    fetchPromise = fetchAndActivate(remoteConfig);
  }

  return fetchPromise;
};

export { remoteConfig, fetchAndActivateOnce };
