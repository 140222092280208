import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as VerifyIbanTypes from './verify.types';

//
//

export class ManagerIban extends ZidderResource<ManagerIban> {
  protected classInstance = ManagerIban;

  public pathBase = 'v1/managers/store/verify-iban';

  //

  async verify(payload?: VerifyIbanTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase, {
      json: payload,
      ...options,
    });
    const result = await response.json<VerifyIbanTypes.Response>();

    if (!result.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //
}
