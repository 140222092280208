import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as SmsCampaignTypes from './types';

//
//

export class ManagerSmsCampaign extends ZidderResource<ManagerSmsCampaign> {
  protected classInstance = ManagerSmsCampaign;
  public pathBase = 'v1/managers/store/';

  //

  async list(query?: SmsCampaignTypes.SmsCampaignListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'sms-campaigns', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<SmsCampaignTypes.SmsCampaignListResponse>();

    if (!result?.campaigns) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get(params: SmsCampaignTypes.SmsCampaignQuery, options?: ZidderRequestOptions) {
    const { id } = params;

    const response = await this.client.get(this.pathBase + `sms-campaigns/${id}`, options);
    const result = await response.json<SmsCampaignTypes.SmsCampaignResponse>();

    if (!result?.smsCampaign) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    id?: SmsCampaignTypes.UpdatePayload['id'],
    payload?: SmsCampaignTypes.UpdatePayload['data'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + `sms-campaigns/${id}`, {
      json: payload,
      ...options,
    });

    const result = await response.json<SmsCampaignTypes.UpdateResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload: SmsCampaignTypes.CreatePayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'sms-campaigns', {
      json: payload,
      ...options,
    });

    const result = await response.json<SmsCampaignTypes.CreateResponse>();

    if (!result?.smsCampaign) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async listSmsCampaignsCustomers(
    query: SmsCampaignTypes.SmsCampaignCustomerQuery,
    options?: ZidderRequestOptions,
  ) {
    const { id, ...rest } = query;

    const response = await this.client.get(this.pathBase + `sms-campaigns/${id}/customers`, {
      ...options,
      searchParams: { ...rest, ...options?.searchParams },
    });
    const result = await response.json<SmsCampaignTypes.SmsCampaignCustomerResponse>();

    if (!result?.customers) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
