import React from 'react';

import { AlertProps, AlertTitleProps } from '@mui/material';

import { AppAlert } from '~/zidmui/components';

import { AppTypography } from './app-typography';

//
//

export type NotificationProps = {
  heading?: React.ReactNode;
  headingProps?: AlertTitleProps;
  messages?: React.ReactNode | React.ReactNode[];
};

export const Notification = ({
  heading,
  headingProps,
  messages,
  children,
  sx = { mt: 1, minWidth: '50%', maxWidth: 400 },
  ...props
}: Omit<AlertProps, 'variant'> & NotificationProps) => {
  return (
    // @ts-expect-error title typing is being weird
    <AppAlert title={heading || undefined} titleProps={headingProps} sx={sx} {...props}>
      {Array.isArray(messages) && messages?.length
        ? messages.map((item, index) => <AppTypography key={index}>{item}</AppTypography>)
        : null}
      {messages && typeof messages === 'string' ? <AppTypography>{messages}</AppTypography> : null}
      {children}
    </AppAlert>
  );
};
