import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as SendOtpTypes from './send-otp.types';
import * as VerifyOtpTypes from './verify-otp.types';

//
//

export class ManagerZidshipOtp extends ZidderResource<ManagerZidshipOtp> {
  protected classInstance = ManagerZidshipOtp;
  public pathBase = 'v1/managers/store/zidship';

  async sendOtp(data: SendOtpTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/send-otp`, {
      json: data,
      ...options,
    });

    const result = await response.json<SendOtpTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async verifyOtp(data: VerifyOtpTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/verify-otp`, {
      json: data,
      ...options,
    });

    const result = await response.json<VerifyOtpTypes.Response>();

    if (!result.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
