import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as Types from './types';

//
//

export class ManagerSettingsCommunicationsQuestions extends ZidderResource<ManagerSettingsCommunicationsQuestions> {
  protected classInstance = ManagerSettingsCommunicationsQuestions;

  public pathBase = 'v1/managers/store/product-qa/settings/';

  //

  async get(query?: Types.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      searchParams: { ...query },
      ...options,
    });

    const result = await response.json<Types.Response>();

    if (response?.status !== 200) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: Types.UpdatePayload, options?: ZidderRequestOptions) {
    const response = await this.client.patch(this.pathBase, {
      json: payload,
      ...options,
    });

    if (response?.status !== 200) {
      this.throwUnexpectedResponse(response);
    }

    return true;
  }
}
