import { ZidderResource } from '~/zidderjs/classes';
import * as InventoriesTypes from '~/zidderjs/resources/manager/inventories/inventories.types';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as HistoryListTypes from './history.list.types';
import type * as InventoryTypes from './inventories.types';
import * as LocationsListTypes from './locations.list.types';

//
//

export class ManagerInventories extends ZidderResource<ManagerInventories> {
  protected classInstance = ManagerInventories;
  public locationsBase = 'v1/locations';
  public historyPath = 'v1/stock-history';

  async list(query?: LocationsListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(`${this.locationsBase}`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<LocationsListTypes.Response>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async inventoryHistoryList(query?: HistoryListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.historyPath, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<HistoryListTypes.Response>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async inventory(id: string | undefined, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.locationsBase}/${id}`, options);
    const result = await response.json<InventoryTypes.Result>();

    if (!result.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createInventory(data: InventoriesTypes.InventoryCreate, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.locationsBase, { json: data, ...options });

    const result = await response.json<InventoryTypes.Result>();

    if (!result.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async toggleStatus(
    { id, ...params }: InventoryTypes.PayloadUpdate,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.patch(`${this.locationsBase}/${id}/`, {
      json: params,
      ...options,
    });
    const result = await response.json<InventoryTypes.Result>();

    if (!result.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateInventory(
    id: string | undefined,
    data: Partial<InventoryTypes.InventoryCreate>,
    options?: ZidderRequestOptions,
  ) {
    const url = `${this.locationsBase}/${id}`;
    const response = await this.client.patch(url, { json: data, ...options });

    const result = await response.json<InventoryTypes.Result>();

    if (!result.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async enableInventory(id: string, data: { is_enabled: boolean }, options?: ZidderRequestOptions) {
    const url = `${this.locationsBase}/${id}`;

    const response = await this.client.patch(url, { json: data, ...options });

    const result = await response.json<InventoryTypes.Result>();

    if (!result.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteInventory(id: string, options?: ZidderRequestOptions) {
    const url = `${this.locationsBase}/${id}`;
    const response = await this.client.delete(url, { ...options });

    if (!response.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response.json<{ success: boolean }>();
  }
}
