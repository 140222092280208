type FormDataConvertible = {
  [key: string]:
    | string
    | number
    | boolean
    | Blob
    | File
    | undefined
    | (string | number | boolean | Blob | File)[];
};

type FormDataConvertibleForParentKeys = {
  [key: string]: string | number | boolean | File | Blob | undefined | null | object;
};

const isFile = (value: unknown): value is File | Blob =>
  value instanceof File || value instanceof Blob;

const appendBooleanAsNumber = (
  formData: FormData,
  key: string,
  value: boolean,
  asNumber: boolean,
) => {
  formData.append(key, asNumber ? (value ? '1' : '0') : value.toString());
};

const appendFileToFormData = (
  formData: FormData,
  fieldName: string | number | symbol,
  file: File | Blob,
) => {
  const fileName = file instanceof File ? file.name : 'blob';
  const extension = fileName.includes('.') ? fileName.split('.').pop() : '';
  const finalName = extension ? `file.${extension}` : 'file';
  formData.append(String(fieldName), file, finalName);
};

export const prepareFormDataForOneFile = <T extends FormDataConvertible>(
  data: Partial<T>,
  fieldName: keyof T,
  booleanAsNumber = false,
): FormData => {
  const formData = new FormData();

  const fileValue = data[fieldName];

  if (fileValue && isFile(fileValue)) {
    appendFileToFormData(formData, fieldName, fileValue);
  }

  Object.entries(data).forEach(([key, value]) => {
    if (key !== fieldName.toString() && value !== undefined) {
      if (typeof value === 'boolean') {
        appendBooleanAsNumber(formData, key, value, booleanAsNumber);
      } else {
        formData.append(key, String(value));
      }
    }
  });

  return formData;
};

export const prepareFormDataForMultipleFiles = <T extends FormDataConvertible>(
  data: Partial<T>,
  filesKey: keyof T,
): FormData => {
  const formData = new FormData();

  const fileValues = data[filesKey];

  if (Array.isArray(fileValues)) {
    fileValues.forEach(fileValue => {
      if (fileValue && isFile(fileValue)) {
        appendFileToFormData(formData, `${String(filesKey)}[]`, fileValue);
      }
    });
  } else if (fileValues && isFile(fileValues)) {
    appendFileToFormData(formData, filesKey, fileValues);
  }

  Object.entries(data).forEach(([key, value]) => {
    if (key !== filesKey.toString() && value !== undefined) {
      formData.append(key, String(value));
    }
  });

  return formData;
};

export const prepareFormDataForMultipleKeys = <T extends FormDataConvertibleForParentKeys>(
  data: Partial<T>,
  formData: FormData = new FormData(),
  parentKey: string = '',
): FormData => {
  for (const [key, value] of Object.entries(data)) {
    const keyName = parentKey ? `${parentKey}[${key}]` : key;

    if (typeof value === 'object' && value !== null && !(value instanceof File)) {
      prepareFormDataForMultipleKeys(value, formData, keyName);
    } else {
      formData.append(keyName, value instanceof File ? value : String(value));
    }
  }

  return formData;
};
