import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as AddOrderCommentTypes from './add-comment.types';
import * as ChangeOrderStatusTypes from './change-order-status.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as ShippingTypes from './shipping.types';
import * as TagsTypes from './tags.types';
import * as UpdatePaymentStatusTypes from './update-payment-status.types';

//
//

export class ManagerOrders extends ZidderResource<ManagerOrders> {
  protected classInstance = ManagerOrders;
  public pathBase = 'v1/managers/store/orders/';
  public pathBaseOrder = 'v1/managers/order/';

  //

  async list(query: ListTypes.OrdersListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ListTypes.OrdersListResponse>();

    if (!result?.orders) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get({ id }: GetTypes.OrderDetailsQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + id + '/view', options);
    const result = await response.json<GetTypes.OrderDetailsResponse>();

    if (!result?.order?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updatePaymentStatus(
    id: UpdatePaymentStatusTypes.UpdatePaymentStatusPayload['id'],
    order_payment_status: UpdatePaymentStatusTypes.UpdatePaymentStatusPayload['order_payment_status'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/change-order-payment-status`, {
      json: { order_payment_status },
      ...options,
    });
    const result = await response.json<UpdatePaymentStatusTypes.UpdatePaymentStatusResponse>();

    return result;
  }

  //

  async changeOrderStatus(
    id: ChangeOrderStatusTypes.ChangeOrderStatusPayload['orderId'],
    payload: ChangeOrderStatusTypes.ChangeOrderStatusPayload['payload'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/change-order-status`, {
      json: payload,
      ...options,
    });
    const result = await response.json<ChangeOrderStatusTypes.ChangeOrderStatusResponse>();

    return result;
  }

  //

  async addComment(
    payload: AddOrderCommentTypes.AddOrderCommentPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(
      this.pathBase + `${payload.orderId}/add-order-comment`,
      {
        json: { comment: payload.comment },
        ...options,
      },
    );
    const result = await response.json<AddOrderCommentTypes.AddOrderCommentResponse>();

    return result;
  }

  //

  async getTags({ name }: TagsTypes.OrderTagsQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBaseOrder + 'tags', {
      ...options,
      searchParams: { name },
    });
    const result = await response.json<TagsTypes.OrderTagsResponse>();

    if (!result?.order_tags) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async addTag({ orderId, name }: TagsTypes.OrderAddTagPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBaseOrder + `${orderId}/tags`, {
      json: { name },
      ...options,
    });
    const result = await response.json<TagsTypes.OrderAddTagResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deleteTag(
    { orderId, tagId, name }: TagsTypes.OrderDeleteTagPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBaseOrder + `${orderId}/tags/${tagId}`, {
      json: { name },
      ...options,
    });
    const result = await response.json<TagsTypes.OrderDeleteTagResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async confirmBankTransferStatus(
    { orderId }: ChangeOrderStatusTypes.ConfirmBankTransferPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(
      this.pathBase + `${orderId}/change-bank-transfer-status`,
      {
        json: { transaction_status: 'transfered' },
        ...options,
      },
    );
    const result = await response.json<ChangeOrderStatusTypes.ConfirmBankTransferResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateShippingMethod(
    { orderId, shipping_method_id }: ShippingTypes.UpdateOrderShippingMethodPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + `${orderId}/shipping-method`, {
      json: { shipping_method_id },
      ...options,
    });
    const result = await response.json<ShippingTypes.UpdateOrderShippingMethodResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
