import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as DetailsTypes from './details.types';
import * as ListTypes from './list.types';
import * as NotificationsTypes from './notifications.types';
import * as StatsTypes from './stats.types';

//
//

export class ManagerAbandonedCarts extends ZidderResource<ManagerAbandonedCarts> {
  protected classInstance = ManagerAbandonedCarts;
  public pathBase = 'v1/managers/store/abandoned-carts/';
  public pathBaseNotifications = this.pathBase + 'notifications/';

  //

  async list({ searchParams }: ListTypes.AbandonedCartQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      searchParams: { ...searchParams },
      ...options,
    });
    const result = await response.json<ListTypes.AbandonedCartResponse>();

    if (!result?.['abandoned-carts']) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get({ id }: DetailsTypes.AbandonedCartDetailsQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + id, options);
    const result = await response.json<DetailsTypes.AbandonedCartDetailsResponse>();

    if (!result?.abandoned_cart?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async stats(query: StatsTypes.AbandonedCartStatsQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'stats', {
      searchParams: { ...query },
      ...options,
    });
    const result = await response.json<StatsTypes.AbandonedCartStatsResponse>();

    if (!result?.stats) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async validateMerchantMessage(
    payload: NotificationsTypes.ValidateNotficationsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(
      this.pathBaseNotifications + 'merchant-validate-messages',
      {
        ...options,
        json: { ...payload },
      },
    );
    const result = await response.json<NotificationsTypes.ValidateNotficationsResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
