import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';
import { ManagerProfileGetResponse } from '~/zidderjs/resources/manager/account/profile.types';
import { prepareFormDataForOneFile } from '~/zidderjs/utils';

import * as SettingsBusinessTypesCreate from './create.types';
import * as SettingsBusinessTypes from './types';

export class ManagerSettingsInformationStoreBusiness extends ZidderResource<ManagerSettingsInformationStoreBusiness> {
  protected classInstance = ManagerSettingsInformationStoreBusiness;

  public pathBusiness = 'v1/managers/store/business-place';

  async settingsBusiness(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBusiness, {
      ...options,
    });

    const result = await response.json<SettingsBusinessTypes.SettingsInformationBusinessResponse>();

    if (!result?.business_location) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    data: SettingsBusinessTypesCreate.SettingsBusinessLocationPayload,
    options?: ZidderRequestOptions,
  ): Promise<ManagerProfileGetResponse> {
    const body = prepareFormDataForOneFile<Partial<SettingsBusinessTypesCreate.Query>>(
      data,
      'national_address_certificate',
      true,
    );

    body.append('_method', 'put');
    const response = await this.client.post(this.pathBusiness, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<ManagerProfileGetResponse>();

    if (!result?.user) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
