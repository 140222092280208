export const serializeSearchParams = (params: Record<string, string>) => {
  if (!params) return {};

  return Object.entries(params).reduce(
    (acc, [key, value]) => {
      if (value !== undefined) acc[key] = value;

      return acc;
    },
    {} as Record<string, string>,
  );
};
