import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as MetafieldsTypes from './types';

//
//

export class ManagerMetafields extends ZidderResource<ManagerMetafields> {
  protected classInstance = ManagerMetafields;

  public pathBase = 'v1/managers/metafields/';

  //

  async list(query?: MetafieldsTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<MetafieldsTypes.Response>();

    if (!result?.payload?.metafields) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async toggleStatus(payload: MetafieldsTypes.ToggleStatusPayload, options?: ZidderRequestOptions) {
    const response = await this.client.patch(this.pathBase + payload.id + '/status', {
      ...options,
      json: { is_active: payload.is_active },
    });

    const result = await response.json<MetafieldsTypes.ToggleStatusResponse>();

    if (!result?.metafield?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(payload: MetafieldsTypes.DeletePayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + payload.metafield_id, options);

    const result = await response.json<MetafieldsTypes.DeleteResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload: MetafieldsTypes.CreateMetafieldPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });

    const result = await response.json<MetafieldsTypes.CreateMetafieldResponse>();

    if (!result?.metafield?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(params: MetafieldsTypes.GetMetafieldQuery, options?: ZidderRequestOptions) {
    const { id } = params;

    const response = await this.client.get(this.pathBase + id, options);
    const result = await response.json<MetafieldsTypes.GetMetafieldResponse>();

    if (!result?.metafield?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    id?: MetafieldsTypes.UpdatePayload['id'],
    payload?: MetafieldsTypes.UpdatePayload['data'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + id, {
      json: payload,
      ...options,
    });

    const result = await response.json<MetafieldsTypes.UpdateResponse>();

    if (!result?.metafield?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateValues(payload: MetafieldsTypes.UpdateValuesPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'values', {
      json: payload,
      ...options,
    });

    const result = await response.json<MetafieldsTypes.UpdateValuesResponse>();

    if (result?.status !== 'object') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateMedia(payload: MetafieldsTypes.UpdateMediaPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + 'media', {
      body: payload,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<MetafieldsTypes.UpdateMediaResponse>();

    if (!result?.media?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
