import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';
import * as ThirdPartyTypes from './third-party.types';

//
//

export class ManagerZidpay extends ZidderResource<ManagerZidpay> {
  protected classInstance = ManagerZidpay;
  public pathBase = 'v1/managers/store/zidpay/';

  async merchantDetails(options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + 'details', {
      ...options,
    });

    const result = await response.json<ListTypes.ZidpayMerchantDetailsResponse>();

    if (!result?.accountTypeId) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getThirdPartyDetailsByStoreId(options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + 'get-third-party-details-by-store-id', {
      ...options,
    });

    const result = await response.json<ThirdPartyTypes.ZidpayThirdPartyDetailsResponse>();

    return result;
  }
}
