import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as Types from './update.types';

//
//

export class ManagerLocations extends ZidderResource<ManagerLocations> {
  protected classInstance = ManagerLocations;

  public pathBase = 'v1/locations';
  //

  async updateStock({ json, location_id }: Types.Payload, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/${location_id}/stock-update`, {
      ...options,
      json,
    });

    if (response.status === 204) return {};

    return await response.json<Types.Response>();
  }
}
