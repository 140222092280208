import { serializeSearchParams } from '~/global/utils/serialize-search-params';

import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ActivateTypes from './activate.types';
import * as DeleteTypes from './delete.types';
import * as DisableTypes from './disable.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as PricingZoneCreateTypes from './pricing-zone-create.types';
import * as PricingZoneDeleteTypes from './pricing-zone-delete.types';
import * as PricingZoneDisableTypes from './pricing-zone-disable.types';
import * as PricingZoneEnableTypes from './pricing-zone-enable.types';
import * as PricingZoneUpdateTypes from './pricing-zone-update.types';
//
//
export class ManagerZidshipServiceLevels extends ZidderResource<ManagerZidshipServiceLevels> {
  protected classInstance = ManagerZidshipServiceLevels;
  public pathBase = 'v1/managers/store/zidship';

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + '/service-levels', {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.service_levels) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async disable(data: DisableTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(`${this.pathBase}/disable`, {
      json: data,
      ...options,
    });

    const result = await response.json<DisableTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async activate(data: ActivateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}/activate`, {
      json: data,
      ...options,
    });

    const result = await response.json<ActivateTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(query: GetTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(
      `${this.pathBase}/service-levels/${query.service_level_code}/configuration`,
      {
        ...options,
        searchParams: serializeSearchParams({ city_id: query.city_id ?? '' }),
      },
    );

    const result = await response.json<GetTypes.Response>();

    if (!result?.configurations) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(query: DeleteTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.delete(
      `${this.pathBase}/service-levels/${query.service_level_code}/configuration`,
      {
        ...options,
      },
    );

    const result = await response.json<DeleteTypes.Response>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createPricingZone(query: PricingZoneCreateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(
      `${this.pathBase}/service-levels/${query.service_level_id}/customizations`,
      {
        ...options,
        json: query.payload,
      },
    );

    const result = await response.json<PricingZoneCreateTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updatePricingZone(query: PricingZoneUpdateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.put(
      `${this.pathBase}/service-levels/${query.service_level_id}/customizations/${query.payload.id}`,
      {
        json: query.payload,
        ...options,
      },
    );

    const result = await response.json<PricingZoneUpdateTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async disablePricingZone(query: PricingZoneDisableTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(
      `${this.pathBase}/service-levels/${query.service_level_id}/customizations/${query.zone_id}/disable`,
      {
        ...options,
      },
    );

    const result = await response.json<PricingZoneDisableTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async enablePricingZone(query: PricingZoneEnableTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(
      `${this.pathBase}/service-levels/${query.service_level_id}/customizations/${query.zone_id}/enable`,
      {
        ...options,
      },
    );

    const result = await response.json<PricingZoneEnableTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deletePricingZone(query: PricingZoneDeleteTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.delete(
      `${this.pathBase}/service-levels/${query.service_level_id}/customizations/${query.zone_id}`,
      {
        ...options,
      },
    );

    const result = await response.json<PricingZoneDeleteTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
