import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as DomainsTypes from './domains.types';
import * as DomainsListTypes from './list.types';
import * as DomainsRecordsTypes from './records.types';

//
//

export class ManagerSettingsDomains extends ZidderResource<ManagerSettingsDomains> {
  protected classInstance = ManagerSettingsDomains;
  public pathBase = 'v1/managers/store/domains/';

  //

  async listDomains(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, { ...options });
    const result = await response.json<DomainsListTypes.DomainsListResponse>();

    if (!result?.domains) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async listDomainRecords(
    { id }: DomainsRecordsTypes.DomainRecordsQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + `${id}/records`, {
      ...options,
    });
    const result = await response.json<DomainsRecordsTypes.DomainRecordsResponse>();

    if (!result?.domain) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getDomainDetails({ id }: DomainsTypes.DomainDetailsQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + `${id}/details`, {
      ...options,
    });
    const result = await response.json<DomainsTypes.DomainDetailsResponse>();

    if (!result?.domain) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async linkDomain(payload: DomainsTypes.DomainsLinkPayload) {
    const response = await this.client.post(this.pathBase, { json: payload });
    const result = await response.json<DomainsTypes.DomainsLinkResponse>();

    if (!result?.domain_id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async verifyDomain({ id }: DomainsTypes.DomainVerifyPayload) {
    const response = await this.client.post(this.pathBase + `${id}/verify`);

    return await response.json<DomainsTypes.DomainVerifyResponse>();
  }

  //

  async deleteDomain({ id }: DomainsTypes.DomainDeletePayload) {
    const response = await this.client.delete(this.pathBase + `${id}/delete`);

    return await response.json<DomainsTypes.DomainDeleteResponse>();
  }

  //

  async activateDomain({ id }: DomainsTypes.DomainActivatePayload) {
    const response = await this.client.post(this.pathBase + `${id}/activate`);

    return await response.json<DomainsTypes.DomainActivateResponse>();
  }

  //

  async deleteRecord({ domainId, recordId }: DomainsTypes.DomainRecordDeletePayload) {
    const response = await this.client.delete(this.pathBase + `${domainId}/records/${recordId}`);

    return await response.json<DomainsTypes.DomainRecordDeleteResponse>();
  }

  //

  async addNewRecord({ domainId, payload }: DomainsTypes.AddNewRecordPayload) {
    const response = await this.client.post(this.pathBase + `${domainId}/records`, {
      json: payload,
    });
    const result = await response.json<DomainsTypes.AddNewRecordResponse>();

    if (!result.data.domain_record) {
      this.throwUnexpectedResponse(response);
    }

    return await response.json<DomainsTypes.AddNewRecordResponse>();
  }
}
