import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as Types from './types';

//
//

export class ManagerModerators extends ZidderResource<ManagerModerators> {
  protected classInstance = ManagerModerators;
  public pathBase = 'v1/managers/moderators';

  //

  async list(query?: Types.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<Types.Response>();

    if (!result?.moderators) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
