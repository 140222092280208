import { t } from 'i18next';
import { HTTPError } from 'ky';
import { enqueueSnackbar } from 'notistack';

import {
  ZidderBadRequest,
  ZidderError,
  ZidderErrorNotFound,
  ZidderErrorUnauthorized,
  ZidderErrorForbidden,
  ZidderErrorTooManyRequests,
  ZidderErrorTimeout,
  ZidderErrorUnexpectedResponse,
  ZidderErrorServer,
} from '~/zidderjs/errors';

// Define the structure of the validation item
type ValidationItem = string | { errors?: string[] };

// Define the structure of the response message
interface ResponseMessage {
  type: string;
  code: string;
  name: string;
  description?: string;
  validations?: ValidationItem[];
}

// Define the structure of the entire error response
interface ErrorResponse {
  status: string;
  message: ResponseMessage;
}

// Define a union type for possible errors
type ExpectedAppError =
  | ZidderError
  | ZidderBadRequest
  | ZidderErrorNotFound
  | ZidderErrorUnauthorized
  | ZidderErrorForbidden
  | ZidderErrorTooManyRequests
  | ZidderErrorTimeout
  | ZidderErrorUnexpectedResponse
  | ZidderErrorServer;

//
//

export const globalErrorHandler = async (error: ExpectedAppError | Error) => {
  try {
    if (error instanceof HTTPError && error?.response) {
      const response = (await error.response.clone().json()) as ErrorResponse;

      const defaultHeading = t('common:somethingWentWrong');

      const heading = response?.message?.description ?? defaultHeading;
      const messages = (response?.message?.validations ?? [])
        .map(item => (typeof item === 'string' ? item : item?.errors || []))
        .flat();

      const validationMessages = Object.keys(response)
        .map(key => {
          const message = response[key as keyof ErrorResponse];

          if (Array.isArray(message)) return message;

          return [];
        })
        .flat();

      //

      enqueueSnackbar({
        variant: 'error',
        heading: heading,
        messages: [...messages, ...validationMessages],
      });

      return;
    }

    console.warn('req-error-2', error); // eslint-disable-line no-console
  } catch (err) {
    console.log('rq-error-1', err, error); // eslint-disable-line no-console
  }
};
