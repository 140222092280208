import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as FaqTypes from './types';

//
//

export class ManagerFAQs extends ZidderResource<ManagerFAQs> {
  protected classInstance = ManagerFAQs;

  public pathBase = 'v1/managers/store/faqs/';

  //

  async list(query?: FaqTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query },
    });

    const result = await response.json<FaqTypes.Response>();

    if (!result?.faqs) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async delete({ id }: FaqTypes.DeleteFaqPayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + id, options);
    const result = await response.json<FaqTypes.DeleteFaqResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async create(payload?: FaqTypes.CreateFAQPayload, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase, {
      json: payload,
      ...options,
    });

    const result = await response.json<FaqTypes.CreateFAQResponse>();

    if (!result?.store_faq) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update(
    id?: FaqTypes.UpdateFAQPayload['id'],
    payload?: FaqTypes.UpdateFAQPayload['data'],
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + id, {
      json: payload,
      ...options,
    });

    const result = await response.json<FaqTypes.UpdateFAQResponse>();

    if (!result?.store_bank.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
