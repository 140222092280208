import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

export class ManagerResellersSettlements extends ZidderResource<ManagerResellersSettlements> {
  protected classInstance = ManagerResellersSettlements;
  public pathBase = `v1/managers/resellers`;

  async list({ reseller_id, ...query }: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + `/${reseller_id}/settlements`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
