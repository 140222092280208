import { useLocation, useMatches } from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import { useEffect } from 'react';

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_ENVIRONMENT,
    tracesSampleRate: 1.0,
    ignoreErrors: [
      'Check Internet connection',
      'Load failed',
      'Failed to fetch',
      'Request failed with status code 400',
      'Request failed with status code 401',
      'Request failed with status code 422',
      'The database connection is closing',
      'AppErrorNotFound',
      'TimeoutError',
      'ZidderError',
      'ZidderBadRequest',
      'ZidderErrorServer',
      'ZidderErrorNotFound',
      'ZidderErrorUnauthorized',
      'ZidderErrorForbidden',
      'ZidderErrorTooManyRequests',
      'ZidderErrorTimeout',
      'ZidderErrorUnexpectedResponse',
      'AppErrorGeneric',
      'AppErrorIncorrectId',
      'AppErrorMissingPermission',
      'AppErrorUpgradeRequired',
      'AppErrorNoInternetConnection',
    ],
    integrations: [
      Sentry.browserTracingIntegration({
        useEffect,
        useLocation,
        useMatches,
      }),
    ],
  });
}
