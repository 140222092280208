import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type {
  AbandonedCartsMessagesCreatePayload,
  AbandonedCartsMessagesCreateResponse,
} from './create.types';
import * as AbandonedCartsMessagesTypes from './list.types';
import * as NotificationTypes from './notification.types';
import type {
  AbandonedCartsMessagesUpdatePayload,
  AbandonedCartsMessagesUpdateResponse,
} from './update.types';

//
//

export class ManagerScheduledNotificationsSettings extends ZidderResource<ManagerScheduledNotificationsSettings> {
  protected classInstance = ManagerScheduledNotificationsSettings;
  public pathBase = 'v1/managers/store/scheduled-notifications/settings/';

  //

  async listMessages(
    query?: AbandonedCartsMessagesTypes.ListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + 'abandoned-carts-settings', {
      ...options,
      searchParams: { ...query?.searchParams, ...options?.searchParams },
    });
    const result = await response.json<AbandonedCartsMessagesTypes.ListResponse>();

    if (!result?.scheduled_notification_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async createAbandonedCartsMessages(
    payload?: AbandonedCartsMessagesCreatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase, {
      ...options,
      json: payload,
    });
    const result = await response.json<AbandonedCartsMessagesCreateResponse>();

    if (!result?.scheduled_notification_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateAbandonedCartsMessages(
    { id, payload }: AbandonedCartsMessagesUpdatePayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + id, {
      ...options,
      json: { ...payload },
    });
    const result = await response.json<AbandonedCartsMessagesUpdateResponse>();

    if (!result?.scheduled_notification_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deleteNotification(
    payload: NotificationTypes.NotificationDeletePayload,
    options?: ZidderRequestOptions,
  ) {
    // Api doesn't return response
    const response = await this.client.delete(this.pathBase + payload.id, {
      ...options,
    });

    if (!response.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response.ok;
  }

  //

  async activateAutomaticNotification(
    payload: NotificationTypes.NotificationActivatePayload,
    options?: ZidderRequestOptions,
  ) {
    // Api doesn't return response
    const response = await this.client.put(this.pathBase + 'activate/' + payload.id, {
      ...options,
      json: { activate: payload.activate },
    });
    const result = await response.json<NotificationTypes.NotificationActivateResponse>();

    if (!result?.scheduled_notification_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
