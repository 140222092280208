import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as LegalPageTypes from './types';

//
//

export class ManagerPrivacyPolicy extends ZidderResource<ManagerPrivacyPolicy> {
  protected classInstance = ManagerPrivacyPolicy;
  public pathBase = 'v1/managers/store/privacy-policy';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });

    const result = await response.json<LegalPageTypes.PrivacyPolicyViewResponse>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: LegalPageTypes.UpdatePrivacyPolicyPayload, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase, {
      ...options,
      json: payload,
    });

    const result = await response.json<LegalPageTypes.UpdateGeneralResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
