import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as DraftsTypes from './drafts.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as UpdateTypes from './update.types';

//
//

export class ManagerOrdersDrafts extends ZidderResource<ManagerOrdersDrafts> {
  protected classInstance = ManagerOrdersDrafts;
  public pathBase = 'v1/managers/store/drafts/';

  //

  async list(query?: ListTypes.DraftsListQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      searchParams: { ...query },
      ...options,
    });
    const result = await response.json<ListTypes.DraftsListResponse>();

    if (!result?.drafts) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getDraftSession(query?: GetTypes.DraftGetQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + `${query?.id}/view`, {
      ...query,
      ...options,
    });
    const result = await response.json<GetTypes.DraftGetResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async verifyDraftSession(payload?: GetTypes.VerifyDraftQuery, options?: ZidderRequestOptions) {
    const response = await this.client.post(this.pathBase + `${payload?.id}/verify`, {
      ...options,
    });
    const result = await response.json<GetTypes.VerifyDraftResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getDraftCustomerAddresses(
    query: GetTypes.GetDraftCustomerAddressesQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + `${query.id}/customer-addresses`, {
      ...options,
    });
    const result = await response.json<GetTypes.GetDraftCustomerAddressesResponse>();

    if (!result?.customer_addresses) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getDraftShippingMethods(
    query: GetTypes.GetDraftShippingMethodsQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + `${query.id}/shipping-methods`, {
      ...options,
    });
    const result = await response.json<GetTypes.GetDraftShippingMethodsResponse>();

    if (!result?.shipping_methods) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDraftShippingMethods(
    { id, ...payload }: UpdateTypes.UpdateDraftShippingMethodPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/shipping-methods/add`, {
      ...options,
      body: JSON.stringify(payload),
    });
    const result = await response.json<UpdateTypes.UpdateDraftShippingMethodResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async createDraftSessionId(
    payload: DraftsTypes.CreateOrderDraftPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'create-session-id', {
      json: payload,
      ...options,
    });
    const result = await response.json<DraftsTypes.CreateOrderDraftResponse>();

    if (!result?.session_id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async delete({ id }: DraftsTypes.DraftsDeletePayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + `${id}/delete`, options);
    const result = await response.json<DraftsTypes.DraftsDeleteResponse>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDraftCurrency(
    { id, order_draft_currency }: UpdateTypes.UpdateOrderDraftPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/currency`, {
      body: JSON.stringify({ order_draft_currency }),
      ...options,
    });
    const result = await response.json<UpdateTypes.UpdateOrderDraftResponse>();

    return result;
  }

  //

  async addDraftProduct(
    { id, products }: UpdateTypes.AddDraftProductsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/products/add`, {
      json: { products },
      ...options,
    });
    const result = await response.json<UpdateTypes.AddDraftProductsResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deleteDraftProduct(
    { id, productId }: UpdateTypes.DeleteDraftProductsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(this.pathBase + `${id}/products/delete`, {
      body: JSON.stringify({ id: productId }),
      ...options,
    });
    const result = await response.json<UpdateTypes.DeleteDraftProductsResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDraftProductQuantity(
    query: UpdateTypes.UpdateDraftProductQuantityPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + `${query.id}/products/update`, {
      body: JSON.stringify({ id: query.productId, quantity: query.quantity }),
      ...options,
    });
    const result = await response.json<UpdateTypes.UpdateDraftProductQuantityResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDraftCustomer(
    query: UpdateTypes.UpdateDraftCustomerPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${query.id}/customer`, {
      body: JSON.stringify({ customer_id: query.customer_id }),
      ...options,
    });
    const result = await response.json<UpdateTypes.UpdateDraftCustomerResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updateDraftCustomerAddress(
    { id, ...query }: UpdateTypes.UpdateDraftCustomerAddressPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/customer/address`, {
      body: JSON.stringify(query),
      ...options,
    });
    const result = await response.json<UpdateTypes.UpdateDraftCustomerAddressResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getPaymentMethods(
    { id }: GetTypes.GetDraftPaymentMethodsQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.pathBase + `${id}/payment-methods`, {
      ...options,
    });
    const result = await response.json<GetTypes.GetDraftPaymentMethodsResponse>();

    if (!result?.cart_payment_methods) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updatePaymentMethod(
    { id, payload }: UpdateTypes.UpdateDraftPaymentMethodPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `${id}/payment-methods/add`, {
      ...options,
      body: JSON.stringify(payload),
    });
    const result = await response.json<UpdateTypes.UpdateDraftPaymentMethodResponse>();

    if (!result?.draft) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
