import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as SettingsTeamTypes from './types';

//
//

export class ManagerSettingsTeam extends ZidderResource<ManagerSettingsTeam> {
  protected classInstance = ManagerSettingsTeam;
  public pathBase = 'v1/managers';

  async list(query?: SettingsTeamTypes.SettingsTeamListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};
    const response = await this.client.get(`${this.pathBase}/moderators`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<SettingsTeamTypes.SettingsTeamListResponse>();

    if (!('moderators' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async groupList(
    query?: SettingsTeamTypes.SettingsTeamGroupListQuery,
    options?: ZidderRequestOptions,
  ) {
    if (!options) options = {};
    const response = await this.client.get(`${this.pathBase}/permission-groups`, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<SettingsTeamTypes.SettingsTeamGroupListResponse>();

    if (!('payload' in result.message)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async delete(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}/moderators/${id}`, {
      ...options,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteGroup(id: string, options?: ZidderRequestOptions) {
    const response = await this.client.delete(`${this.pathBase}/permission-groups/${id}`, {
      ...options,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async permissionList(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}/permissions-grouped`, {
      ...options,
    });

    const result = await response.json<SettingsTeamTypes.SettingsTeamPermissionListResponse>();

    if (!('sections' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async create(payload: SettingsTeamTypes.SettingsTeamCreatePayload) {
    const response = await this.client.post(`${this.pathBase}/moderators`, {
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(payload: SettingsTeamTypes.SettingsTeamUpdatePayload) {
    const response = await this.client.put(`${this.pathBase}/moderators/${payload.id}`, {
      json: payload,
    });

    const result = await response.json<ZidderResponse<'object', 'success'>>();

    if (result.status !== 'object') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async view(params: SettingsTeamTypes.SettingsTeamViewQuery, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}/moderators/${params.id}/settings`, {
      ...options,
    });

    const result = await response.json<SettingsTeamTypes.SettingsTeamViewResponse>();

    if (!('moderator' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updatePermissionsList(payload: SettingsTeamTypes.SettingsTeamUpdatePermissionsPayload) {
    const response = await this.client.post(
      `${this.pathBase}/moderators/${payload.id}/permissions`,
      {
        json: payload,
      },
    );

    const result = await response.json<ZidderResponse<'object', 'success'>>();

    if (result.status !== 'object') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async syncUserGroup(payload: SettingsTeamTypes.SettingsTeamSyncUserGroupPayload) {
    const response = await this.client.post(
      `${this.pathBase}/permission-groups/user/${payload.id}/sync-group`,
      {
        json: payload,
      },
    );

    const result = await response.json<ZidderResponse<'object', 'success'>>();

    if (result.status !== 'object') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createGroup(payload: SettingsTeamTypes.SettingsTeamCreateGroupPayload) {
    const response = await this.client.post(`${this.pathBase}/permission-groups`, {
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async groupDetail(
    params: SettingsTeamTypes.SettingsTeamGroupDetailQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(`${this.pathBase}/permission-groups/${params.id}`, {
      ...options,
    });

    const result = await response.json<SettingsTeamTypes.SettingsTeamGroupDetailResponse>();

    if (!('payload' in result.message)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateGroup(payload: SettingsTeamTypes.SettingsTeamUpdateGroupPayload) {
    const response = await this.client.post(`${this.pathBase}/permission-groups/${payload.id}`, {
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
