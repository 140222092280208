import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import type * as GetTypes from './get.types';
import type * as UpdateTypes from './update.types';
//
//

export class SettingsVat extends ZidderResource<SettingsVat> {
  protected classInstance = SettingsVat;
  public pathBase = 'v1/managers/store/vat';

  //

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
    });

    const result = await response.json<GetTypes.Response>();

    if (!result?.tax_settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update(data: UpdateTypes.Query, options?: ZidderRequestOptions) {
    const response = await this.client.post(`${this.pathBase}`, {
      ...options,
      body: JSON.stringify(data),
    });

    const result = await response.json<UpdateTypes.Response>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
