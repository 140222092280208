import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';
import { ManagerProfileGetResponse } from '~/zidderjs/resources/manager/account/profile.types';

import * as SettingsInformationStoreTypes from './types';

export class ManagerSettingsStoreAboutInformation extends ZidderResource<ManagerSettingsStoreAboutInformation> {
  protected classInstance = ManagerSettingsStoreAboutInformation;
  public pathBase = 'v1/managers/store/about';

  //

  async update(
    payload: SettingsInformationStoreTypes.SettingsInformationPayload,
    options?: ZidderRequestOptions,
  ): Promise<ManagerProfileGetResponse> {
    const response = await this.client.put(this.pathBase, {
      ...options,
      json: payload,
    });
    const result = await response.json<ManagerProfileGetResponse>();

    if (!result?.user) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
