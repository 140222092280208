import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import { StoreSettingsResponse } from '../settings-store/types';

import * as GiftingTypes from './types';

//
//

export class ManagerSettingsGifting extends ZidderResource<ManagerSettingsGifting> {
  protected classInstance = ManagerSettingsGifting;

  public pathBase = 'v1/managers/store/';

  //

  async upload(
    data: GiftingTypes.GiftingSettingsFileUploadPayload,
    options?: ZidderRequestOptions,
  ) {
    const formData = new FormData();

    const fileValue = data['file'];
    formData.append('cards[0][file]', fileValue);

    const response = await this.client.post(this.pathBase + 'gift-card-upload', {
      ...options,
      body: formData,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<StoreSettingsResponse>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
