import { ZidderResource } from '~/zidderjs/classes';
import * as InfoTypes from '~/zidderjs/resources/manager/loyalty-program-info/types';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as Types from './types';

//
//

export class ManagerLoyaltyProgram extends ZidderResource<ManagerLoyaltyProgram> {
  protected classInstance = ManagerLoyaltyProgram;

  public pathBase = 'v1/managers/loyalty-program/';
  public pathRedemptionPoints = this.pathBase + 'points-redemption/';

  //

  async getListData(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'list-data', options);
    const result = await response.json<Types.Response>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //
  // redemption methods

  async createPointRedemption(
    payload: Types.CreatePointRedemptionQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathRedemptionPoints, {
      ...options,
      json: payload,
    });

    const result = await response.json<Types.CreatePointRedemptionResponse>();

    if (!result.points_redemption.redemptionRule.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updatePointRedemption(
    payload: Types.UpdatePointRedemptionQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathRedemptionPoints + 'update', {
      ...options,
      json: payload,
    });

    const result = await response.json<Types.UpdatePointRedemptionResponse>();

    if (!result.points_redemption.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async deletePointRedemption(
    query: Types.DeletePointRedemptionQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathRedemptionPoints + 'delete', {
      ...options,
      json: query,
    });

    const result = await response.json<Types.DeletePointRedemptionResponse>();

    if (!result.points_redemption.status) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async togglePointRedemptionStatus(
    payload: Types.UpdatePointRedemptionStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathRedemptionPoints + 'update-status', {
      ...options,
      json: payload,
    });

    const result = await response.json<Types.UpdatePointRedemptionStatusResponse>();

    if (!result?.redemption_rule.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //
  // cashback points

  async updatePointsExpiration(
    payload: Types.UpdatePointsExpirationQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'points-expiration', {
      ...options,
      json: payload,
    });

    const result = await response.json<Types.UpdatePointsExpirationResponse>();

    if (!result.points_expiry_period) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async updatePointCollection(
    payload: Types.UpdatePointCollectionQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'points-collection/update', {
      ...options,
      json: payload,
    });

    const result = await response.json<Types.UpdatePointCollectionResponse>();

    if (!result?.data.points_collection_methods_list.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async togglePointCollectionStatus(
    payload: Types.UpdatePointCollectionStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + 'points-collection/update-status', {
      ...options,
      json: payload,
    });

    if (!response.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response.ok;
  }

  //

  async toggleLoyaltyProgramStatus(
    payload: InfoTypes.UpdateLoyaltyProgramStatusPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + 'activation', {
      ...options,
      json: payload,
    });

    const result = await response.json<InfoTypes.UpdateLoyaltyProgramStatusResponse>();

    if (!result?.loyalty_program) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
