import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

//
//

export class ManagerStoreShippingMethods extends ZidderResource<ManagerStoreShippingMethods> {
  protected classInstance = ManagerStoreShippingMethods;
  public basePath = 'v1/managers/store/shipping-methods/';

  //

  async list(query?: ListTypes.StoreShippingMethodsListRequest, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.basePath, {
      ...options,
      searchParams: { ...query },
    });
    const result = await response.json<ListTypes.StoreShippingMethodsListResponse>();

    if (!result?.shipping_methods) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getAllShippingMethods(
    query?: ListTypes.ShippingMethodsListQuery,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(this.basePath + 'compact', {
      ...options,
      ...query,
    });
    const result = await response.json<ListTypes.ShippingMethodsListResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
