import { ChangePasswordPayload } from '~/services';
import { ZidderResource } from '~/zidderjs/classes';
import { Account } from '~/zidderjs/resources/manager/account/account.types';
import { ZidderRequestOptions } from '~/zidderjs/types';

import { ManagerProfileGetResponse } from './profile.types';

//
//

export class ManagerAccount extends ZidderResource<ManagerAccount> {
  protected classInstance = ManagerAccount;
  public pathBase = 'v1/managers/account/';

  //

  async profile(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'profile', options);

    const result = await this.getResponseJSON<ManagerProfileGetResponse>(response);

    if (!result?.user?.store?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateAccount(data: Account, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + 'info', { json: data, ...options });
    const result = await response.json<ManagerProfileGetResponse>();

    if (!result?.user?.store?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updatePassword(data: ChangePasswordPayload, options?: ZidderRequestOptions) {
    const response = await this.client.put(this.pathBase + 'password/change', {
      json: data,
      ...options,
    });
    const result = await response.json<{ message: string }>();

    if (!result?.message) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
