import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { prepareFormDataForMultipleFiles, zidderGetHeaders } from '~/zidderjs/utils';

import * as AutoCreateTicketingTypes from './auto-create.types';
import * as GetPrimaryDataTypes from './get-categories-data.types';
import * as GetTypes from './get.types';
import * as ListPrimaryDataTypes from './list-categories-data.types';
import * as ListTypes from './list.types';

//
//

export class ManagerTicketing extends ZidderResource<ManagerTicketing> {
  protected classInstance = ManagerTicketing;
  public pathBase = 'v1/managers/ticketing';

  public ticketsPath = this.pathBase + '/tickets';
  public categoriesPath = this.pathBase + '/categories';

  //

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.ticketsPath, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.items) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get(params: GetTypes.Query, options?: ZidderRequestOptions) {
    const { id } = params;

    const response = await this.client.get(this.ticketsPath + `/${id}`, options);
    const result = await response.json<GetTypes.Response>();

    if (!result?.ticket_with_replies?.ticket?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async create(data: GetTypes.CreateQuery, options?: ZidderRequestOptions) {
    const body = prepareFormDataForMultipleFiles<Partial<GetTypes.CreateQuery>>(
      data,
      'attachments',
    );

    const response = await this.client.post(`${this.ticketsPath}`, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<GetTypes.CreateResponse>();

    if (!result?.ticket_with_replies?.ticket.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async createAttachment(
    id: GetTypes.CreateAttachmentQuery['id'],
    payload: GetTypes.CreateAttachmentQuery['data'],
    options?: ZidderRequestOptions,
  ) {
    const body = prepareFormDataForMultipleFiles<Partial<GetTypes.CreateAttachmentQuery['data']>>(
      payload,
      'attachments',
    );

    const response = await this.client.post(`${this.ticketsPath}/${id}`, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });

    const result = await response.json<GetTypes.CreateAttachmentResponse>();

    if (!result?.reply.length) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async listPrimaryData(query?: ListPrimaryDataTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.categoriesPath, {
      ...options,
      headers: zidderGetHeaders(),
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListPrimaryDataTypes.Response>();

    if (!result?.categories) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async getPrimaryData(params: GetPrimaryDataTypes.Query, options?: ZidderRequestOptions) {
    const { id } = params;

    const response = await this.client.get(this.categoriesPath + `/${id}`, options);
    const result = await response.json<GetPrimaryDataTypes.Response>();

    if (!result?.sub_categories) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async autoCreateTicket(
    { orderId }: AutoCreateTicketingTypes.AautoCreateTicketingPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(this.pathBase + `/auto-create/${orderId}`, {
      ...options,
    });
    const result = await response.json<AutoCreateTicketingTypes.AautoCreateTicketingResponse>();

    return result;
  }
}
