import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as GetTypes from './get.types';
import * as ListTypes from './list.types';

//
//

export class ManagerProducts extends ZidderResource<ManagerProducts> {
  protected classInstance = ManagerProducts;
  public pathBase = 'v1/products/';

  //

  async list(query?: ListTypes.ProductsListQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });
    const result = await response.json<ListTypes.Response>();

    if (!result?.results) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async get(query: GetTypes.ProductQuery, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase + query.id, options);
    const result = await response.json<GetTypes.ProductResponse>();

    if (!result?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteProductByCategory(
    query: ListTypes.DeleteCategoryProductPayload,
    options?: ZidderRequestOptions,
  ) {
    const { category_id, product_id } = query;

    const response = await this.client.delete(
      this.pathBase + `${product_id}/categories/${category_id}`,
      {
        ...options,
      },
    );

    if (!response?.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response.ok;
  }

  async addProductToCategory(
    payload: ListTypes.AddProductToCategoryPayload,
    options?: ZidderRequestOptions,
  ) {
    const { productId, categoryId } = payload;

    const response = this.client.post(`${this.pathBase}${productId}/categories`, {
      json: { id: categoryId },
      ...options,
    });

    return response.json<ListTypes.AddProductToCategoryResponse>();
  }

  //

  async delete(query: ListTypes.DeleteProductQuery, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + query.id, {
      ...options,
    });

    if (!response?.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response?.ok;
  }

  //

  async restore(payload: ListTypes.RestoreProductPayload, options?: ZidderRequestOptions) {
    const response = await this.client.patch(
      this.pathBase + 'restore?deleted=true' + `${payload?.id ? '&id=' + payload.id : ''}`,
      options,
    );

    if (!response?.ok) {
      this.throwUnexpectedResponse(response);
    }

    return response?.ok;
  }
}
