import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';
import { prepareFormDataForOneFile } from '~/zidderjs/utils';

import type * as SendTypes from './send.types';

//
//

export class ManagerIdentityDocument extends ZidderResource<ManagerIdentityDocument> {
  protected classInstance = ManagerIdentityDocument;

  public pathBase = 'v1/managers/store/identity-document';

  //

  async send(data: SendTypes.Query, options?: ZidderRequestOptions) {
    const body = prepareFormDataForOneFile<Partial<SendTypes.Query>>(data, 'file', true);

    const response = await this.client.post(this.pathBase, {
      ...options,
      body,
      headers: {
        ...options?.headers,
        'Content-Type': undefined,
      },
    });
    const result = await response.json<SendTypes.Response>();

    if (!result.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
