import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as ListTypes from './list.types';

//
//

export class ManagerZidposReportsPayments extends ZidderResource<ManagerZidposReportsPayments> {
  protected classInstance = ManagerZidposReportsPayments;
  public pathBase = 'v1/pos/managers/reports/payment-methods-report';

  async list(query?: ListTypes.Query, options?: ZidderRequestOptions) {
    if (!options) options = {};

    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query, ...options?.searchParams },
    });

    const result = await response.json<ListTypes.Response>();

    if (!result?.payload) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
