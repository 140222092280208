import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as SettingsWorktimeTypes from './types';

//
//

export class ManagerSettingsWorktime extends ZidderResource<ManagerSettingsWorktime> {
  protected classInstance = ManagerSettingsWorktime;
  public pathBase = 'v1/managers/store/';

  async getStoreAvailability(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + 'availability', {
      ...options,
    });

    const result =
      await response.json<SettingsWorktimeTypes.SettingsWorktimeStoreAvailabilityResponse>();

    if (!result.settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateStoreAvailability(
    payload: SettingsWorktimeTypes.SettingsWorktimeStoreAvailabilitySettings,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + 'availability', {
      ...options,
      json: payload,
    });

    const result =
      await response.json<SettingsWorktimeTypes.SettingsWorktimeStoreAvailabilityResponse>();

    if (!result.settings) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async updateStoreAvailabilityStatus(
    payload: SettingsWorktimeTypes.SettingsWorktimeStoreAvailabilityStatus,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase + 'availability/status', {
      ...options,
      json: payload,
    });

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
