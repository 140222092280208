import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as ListTypes from './lists.type';

//
//

export class ManagerCustomerAddress extends ZidderResource<ManagerCustomerAddress> {
  protected classInstance = ManagerCustomerAddress;
  public pathBase = 'v1/managers/store/customers';

  async updateCustomerAddress(
    payload: ListTypes.CustomerAddressPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(
      `${this.pathBase}/${payload.customer_id}/addresses/${payload.id}`,
      {
        ...options,
        json: payload,
      },
    );

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async createCustomerAddress(
    payload: ListTypes.CustomerAddressPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(`${this.pathBase}/${payload.customer_id}/addresses`, {
      ...options,
      json: payload,
    });

    const result = await response.json<ListTypes.CreateCustomerAddressResponse>();

    if (!result?.address?.id) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async deleteCustomerAddress(
    payload: ListTypes.DeleteCustomerAddressPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.delete(
      `${this.pathBase}/${payload.customer_id}/addresses/${payload.id}`,
      {
        ...options,
      },
    );

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async setDefaultCustomerAddress(
    payload: ListTypes.SetDefaultCustomerAddressPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post(
      `${this.pathBase}/${payload.customer_id}/addresses/${payload.id}/set-default`,
      {
        ...options,
      },
    );

    const result = await response.json<ZidderResponse<'success', 'success'>>();

    if (result?.status !== 'success') {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async getCustomerAddress(
    payload: ListTypes.GetCustomerAddressesPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.get(`${this.pathBase}/${payload.customer_id}/addresses`, {
      ...options,
    });

    const result = await response.json<ListTypes.GetCustomerAddressResponse>();

    if (!result?.address?.data) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
