import { ZidderRequestOptions } from '~/zidderjs';
import { ZidderResource } from '~/zidderjs/classes';

import * as CommunicationsReviewsListTypes from './communications-reviews.types';

export class ManagerReviewsNotificationsSettings extends ZidderResource<ManagerReviewsNotificationsSettings> {
  protected classInstance = ManagerReviewsNotificationsSettings;
  public pathBase = `v1/managers/store/reviews/notification-settings`;

  async get(options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}`, {
      ...options,
    });
    const result = await response.json<CommunicationsReviewsListTypes.Response>();

    if (!result.settings.customer_notifications || !result.settings.merchant_notifications) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    payload: CommunicationsReviewsListTypes.RatingsNotificationsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.put(this.pathBase, {
      json: { settings: payload },
      ...options,
    });

    const result = await response.json<CommunicationsReviewsListTypes.Response>();

    if (!result.settings.customer_notifications || !result.settings.merchant_notifications) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
