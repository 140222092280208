export const base = {
  white: '#FFFFFF',
  neutral: '#F5F4F6',
  silver: '#E8E4EB',
  black: '#000000',
};

export const primary = {
  50: '#FBF9FF',
  100: '#F8F5FF',
  200: '#F1EAFF',
  300: '#E2D2FF',
  400: '#D2B8FF',
  500: '#C199FF',
  600: '#AE72FF',
  700: '#9C66E4',
  800: '#8758C6',
  900: '#6E48A1',
  1000: '#4E3372',
};

export const secondary = {
  50: '#F8F8F8',
  100: '#F3F2F3',
  200: '#E6E4E7',
  300: '#C9C6CD',
  400: '#A8A3AE',
  500: '#7E7589',
  600: '#3C1C54',
  700: '#36194B',
  800: '#2E1641',
  900: '#261235',
  1000: '#1B0D26',
};

export const neutrals = {
  50: '#F8F8F8',
  100: '#F2F2F2',
  200: '#E4E4E5',
  300: '#C6C6C8',
  400: '#A3A1A6',
  500: '#75727B',
  600: '#1F0433',
  700: '#1C042E',
  800: '#180328',
  900: '#140320',
  1000: '#0E0217',
};

export const green = {
  50: '#FAFDFA',
  100: '#F7FDF7',
  200: '#EFFBEE',
  300: '#DDF8DC',
  400: '#CAF4C8',
  500: '#B6F0B1',
  600: '#9EEC98',
  700: '#8DD388',
  800: '#7AB776',
  900: '#649560',
  1000: '#476A44',
};

export const blue = {
  50: '#F9FDFD',
  100: '#F4FCFC',
  200: '#E9FAFA',
  300: '#D1F4F4',
  400: '#B5EFEF',
  500: '#95E9E9',
  600: '#6BE3E3',
  700: '#60CBCB',
  800: '#53B0B0',
  900: '#449090',
  1000: '#306666',
};

export const yellow = {
  50: '#FEFDF9',
  100: '#FEFDF5',
  200: '#FEFAEB',
  300: '#FDF5D6',
  400: '#FBF0BF',
  500: '#FAEBA4',
  600: '#F9E683',
  700: '#DFCE75',
  800: '#C1B265',
  900: '#9D9153',
  1000: '#6F673B',
};

export const orange = {
  50: '#FFFAF8',
  100: '#FFF6F3',
  200: '#FFEDE6',
  300: '#FFD8C9',
  400: '#FFC2A7',
  500: '#FFA97D',
  600: '#FF8C3A',
  700: '#E47D34',
  800: '#C66C2D',
  900: '#A15925',
  1000: '#723F1A',
};

export const red = {
  50: '#FFF8F9',
  100: '#FFF3F4',
  200: '#FEE5E9',
  300: '#FDC9D0',
  400: '#FCA7B4',
  500: '#FB7C93',
  600: '#FA3768',
  700: '#E0315D',
  800: '#C22B51',
  900: '#9E2342',
  1000: '#70192F',
};

export const colorsShades = {
  base,
  primary,
  secondary,
  neutrals,
  green,
  blue,
  yellow,
  orange,
  red,
};
