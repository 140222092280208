import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions } from '~/zidderjs/types';

import * as CreateTypes from './create.types';
import * as DeleteTypes from './delete.types';
import * as GetTypes from './get.types';
import * as ListTypes from './list.types';
import * as UpdateTypes from './update.types';

//
//

export class ManagerCheckoutConstraints extends ZidderResource<ManagerCheckoutConstraints> {
  protected classInstance = ManagerCheckoutConstraints;
  public pathBase = 'v1/managers/store/checkout-constraints/';

  //

  async list(query?: ListTypes.ListRequest, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
      searchParams: { ...query },
    });
    const result = await response.json<ListTypes.ListResponse>();

    if (!result?.checkout_constraints) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async get({ id }: GetTypes.DetailsRequest, options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase + id, {
      ...options,
    });
    const result = await response.json<GetTypes.DetailsResponse>();

    if (!result) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  //

  async update({ id, data }: UpdateTypes.UpdatePayload) {
    const response = await this.client.put(this.pathBase + id, {
      json: { ...data },
    });

    return await response.json<UpdateTypes.UpdateResponse>();
  }

  //

  async create({ data }: CreateTypes.CreatePayload) {
    const response = await this.client.post(this.pathBase, {
      json: data,
    });

    return await response.json<CreateTypes.CreateResponse>();
  }

  //

  async updateStatus({ id, status }: UpdateTypes.UpdateStatusPayload) {
    const response = await this.client.put(this.pathBase + `${id}/status`, {
      json: { status },
    });

    return await response.json<UpdateTypes.UpdateStatusResponse>();
  }

  //

  async delete({ id }: DeleteTypes.DeletePayload, options?: ZidderRequestOptions) {
    const response = await this.client.delete(this.pathBase + id, {
      ...options,
    });
    const result = await response.json<DeleteTypes.DeleteResponse>();

    return result;
  }
  //

  //

  async listConditions(query?: ListTypes.ConditionsListRequest, options?: ZidderRequestOptions) {
    const response = await this.client.get(`${this.pathBase}conditions`, {
      ...options,
      searchParams: { ...query },
    });

    const result = await response.json<ListTypes.ConditionsListResponse>();

    if (!result?.checkout_constraints_conditions) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }
}
