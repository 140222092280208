import { ZidderResource } from '~/zidderjs/classes';
import { ZidderRequestOptions, ZidderResponse } from '~/zidderjs/types';

import * as SettingsOrderProductsTypes from './types';

//
//

export class ManagerSettingsOrderProducts extends ZidderResource<ManagerSettingsOrderProducts> {
  protected classInstance = ManagerSettingsOrderProducts;
  public pathBase = 'v1/products/settings/';

  //

  async settings(options?: ZidderRequestOptions) {
    const response = await this.client.get(this.pathBase, {
      ...options,
    });

    const result = await response.json<SettingsOrderProductsTypes.SettingsOrderProductsResponse>();

    if (!('settings' in result)) {
      this.throwUnexpectedResponse(response);
    }

    return result;
  }

  async update(
    payload: SettingsOrderProductsTypes.SettingsOrderProductsPayload,
    options?: ZidderRequestOptions,
  ) {
    const response = await this.client.post<ZidderResponse<'object', 'success'>>(this.pathBase, {
      ...options,
      json: payload,
    });

    if (response.status !== 201) {
      this.throwUnexpectedResponse(response);
    }

    return response.ok;
  }
}
